import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, Autocomplete } from '@mui/material';
import { configCountries, configDistricts, configCommunes, configQuartiers } from '../../../../api';

const Location = forwardRef(({ memo, user, t, onValidSubmit }, ref) => {
  const defaultCountry = 'Côte d\'Ivoire';
  const [countriesList, setCountries] = useState([defaultCountry]);
  const [districts, setDistricts] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [quartiers, setQuartiers] = useState([]);
  const [sectorData, setSectorData] = useState(null);

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      country: defaultCountry,
      region: '',
      city: '',
      quartier: '',
      address: '',
      postal_code: '',
    },
    mode: "onChange"
  });

  const country = watch('country');
  const district = watch('region');
  const commune = watch('city');

  useImperativeHandle(ref, () => ({
    submitForm: () => handleSubmit(handleFormSubmit)(),
  }));

  useEffect(() => {
    if (memo) {
      for (const key in memo) {
        setValue(key, memo[key] ?? '');
      }
    }
  }, [memo, setValue]);

  const handleFormSubmit = (data) => {
    let cleanedData = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value) cleanedData[key] = value;
    });
    cleanedData.estimation = sectorData?.Average * cleanedData.size;
    onValidSubmit(cleanedData);
    console.log("Form Location cleanedData submitted", cleanedData);
  };

  useEffect(() => {
    configCountries().then(response => {
      if (!response.data.includes(defaultCountry)) {
        response.data.unshift(defaultCountry);
      }
      setCountries(response.data);
    });
  }, [setValue]);

  const handleCountryChange = (_, newValue) => {
    setValue('country', newValue);
    setValue('region', '');
    setValue('city', '');
    setValue('quartier', '');
    setDistricts([]);
    setCommunes([]);
    setQuartiers([]);
    setSectorData(null);
  };

  useEffect(() => {
    if (country === 'Côte d\'Ivoire') {
      configDistricts().then(response => {
        setDistricts(response.data);
        if (response.data.length > 0) {
          setValue('region', response.data[0]);
        }
      });
    }
  }, [country, setValue]);

  useEffect(() => {
    if (district) {
      configCommunes(district).then(response => {
        setCommunes(response.data);
        if (response.data.length > 0) {
          setValue('city', response.data[0]);
        } else {
          setValue('city', '');
        }
      });
    }
  }, [district, setValue]);

  useEffect(() => {
    if (district && commune) {
      configQuartiers(district, commune).then(response => {
        setQuartiers(response.data);
        if (response.data.length > 0) {
          setValue('quartier', response.data[0]);
        }
        else {
          setValue('quartier', '');
        }
      });
    }
  }, [district, commune, setValue]);

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>

      <Controller
        name="country"
        control={control}
        rules={{ required: t('required') }}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            sx={{ mb: 2 }}
            options={countriesList}
            renderInput={(params) => (
              <TextField {...params} label={t('country')} error={!!fieldState.error} helperText={fieldState.error?.message} />
            )}
            onChange={handleCountryChange}
          />
        )}
      />

      {country === 'Côte d\'Ivoire' && (
        <>
          <Controller
            name="region"
            control={control}
            rules={{ required: t('required') }}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                options={districts}
                sx={{ mb: 2 }}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField {...params} label={t('district')} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                onChange={(_, newValue) => setValue('region', newValue)}
              />
            )}
          />
          {district === 'Abidjan' && (
            <>
              <Controller
                name="city"
                control={control}
                rules={{ required: t('required') }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    options={communes}
                    sx={{ mb: 2 }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} label={t('commune')} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                    onChange={(_, newValue) => setValue('city', newValue)}
                  />
                )}
              />
              <Controller
                name="quartier"
                control={control}
                rules={{ required: t('required') }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    options={quartiers}
                    margin="normal"
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} label={t('quartier')} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                    onChange={(_, newValue) => setValue('quartier', newValue)}
                  />
                )}
              />
            </>
          )}
        </>
      )}
      {country !== 'Côte d\'Ivoire' && (
        <>
          <Controller
            name="city"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t('city')}
                type="text"
                fullWidth
                margin="normal"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="postal_code"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t('postal_code')}
                type="text"
                fullWidth
                margin="normal"
              />
            )}
          />
        </>
      )}

      <Controller
        name="address"
        control={control}
        rules={{ required: t('required') }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={t('address')}
            type="text"
            fullWidth
            margin="normal"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </Box>
  );
});

export default Location;

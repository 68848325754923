import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, Autocomplete, Card, CardContent, Typography } from '@mui/material';
import { configCountries, configDistricts, configCommunes, configQuartiers } from '../../../api';

const Location = forwardRef(({ memo, user, t, onValidSubmit }, ref) => {
  const defaultCountry = 'Côte d\'Ivoire';
  const supportedDistrict = 'Abidjan';
  const [countriesList, setCountries] = useState([defaultCountry]); // Include the default in the initial list
  const [districts, setDistricts] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [quartiers, setQuartiers] = useState([]);
  const [sectorData, setSectorData] = useState(null);

  const { control, handleSubmit, setValue, watch, trigger } = useForm({
    defaultValues: {
      country: defaultCountry,
      region: '',
      city: '',
      quartier: '',
      address: '',
      postal_code: '',
    },
    mode: "onChange"
  });

  const country = watch('country');
  const district = watch('region');
  const commune = watch('city');

  useImperativeHandle(ref, () => ({
    submitForm: () => handleSubmit(handleFormSubmit)(),
  }));

  useEffect(() => {
    if (memo) {
      for (const key in memo) {
        setValue(key, memo[key] ?? '');
      }
    }
  }, [memo, setValue]);

  const handleFormSubmit = (data) => {
    let cleanedData = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        cleanedData[key] = value;
      }
    });

    if (cleanedData.purchase_price) {
      cleanedData.purchase_price = parseFloat(cleanedData.purchase_price);
    }
    cleanedData.estimation = sectorData?.Average * cleanedData.size;

    onValidSubmit(cleanedData);
    console.log("Form Location cleanedData submitted", cleanedData);
  };

  useEffect(() => {
    configCountries().then(response => {
      if (!response.data.includes(defaultCountry)) {
        response.data.unshift(defaultCountry);
      }
      setCountries(response.data);
      if (response.data.length > 0) {
        setValue('country', 'Côte d\'Ivoire');
      }
    });
  }, [setValue]);

  const handleCountryChange = (_, newValue) => {
    setValue('country', newValue);
  }

  useEffect(() => {
    if (country === 'Côte d\'Ivoire') {
      configDistricts().then(response => {
        const fetchedDistricts = response.data;
        setDistricts(fetchedDistricts);
        if (fetchedDistricts.length > 0) {
          setValue('region', fetchedDistricts[0]);
        }
      });
    } else {
      setDistricts([]);
      setCommunes([]);
      setQuartiers([]);
      setValue('region', '');
      setValue('city', '');
      setValue('quartier', '');
      setSectorData(null);
    }
  }, [country, setValue]);

  useEffect(() => {
    if (district) {
      configCommunes(district).then(response => {
        const fetchedCommunes = response.data;
        setCommunes(fetchedCommunes);
        if (fetchedCommunes.length > 0) {
          setValue('city', fetchedCommunes[0]);
        } else setValue('city', '');
      });
    }
  }, [district, setValue]);

  const handleDistrictChange = (_, newValue) => {
    setValue('region', newValue);
  };

  useEffect(() => {
    if (district && commune) {
      configQuartiers(district, commune).then(response => {
        const fetchedQuartiers = response.data;
        setQuartiers(fetchedQuartiers);
        if (fetchedQuartiers.length > 0) {
          setValue('quartier', fetchedQuartiers[0]);
        }
      });
    }
  }, [district, commune, setValue]);

  const handleCommuneChange = async (_, newValue) => {
    setValue('city', newValue);
  };

  const handleQuartierChange = async (_, newValue) => {
    setValue('quartier', newValue);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Controller
        name="country"
        control={control}
        rules={{ required: t('required') }}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            sx={{ mb: 2 }}
            options={countriesList}
            renderInput={(params) => (
              <TextField {...params} label={t('country')} />
            )}
            margin="normal"
            onChange={handleCountryChange}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
      />
      {country === 'Côte d\'Ivoire' && (
        <Box>
          <Controller
            name="region"
            control={control}
            rules={{ required: t('required') }}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                options={districts}
                margin="normal"
                sx={{ mb: 2 }}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField {...params} label={t('district')} />}
                onChange={handleDistrictChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          {district === supportedDistrict && (
            <>
              <Controller
                name="city"
                control={control}
                rules={{ required: t('required') }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    options={communes}
                    margin="normal"
                    sx={{ mb: 2 }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} label={t('commune')} />}
                    onChange={handleCommuneChange}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="quartier"
                rules={{ required: t('required') }}
                control={control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    options={quartiers}
                    margin="normal"
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} label={t('quartier')} />}
                    onChange={handleQuartierChange}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
               <Controller
        name="address"
        control={control}
        rules={{ required: t('required') }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={country === 'Côte d\'Ivoire' ? t('complement_address') : t('address')}
            type="text"
            fullWidth
            margin="normal"
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : ''}
          />
        )}
      />
            </>
          )}
        </Box>
      )}
      {(country !== 'Côte d\'Ivoire' || district !== supportedDistrict) && (
        <Typography color="error">
          {t('location_not_supported')}
        </Typography>
      )}
    </Box>
  );
});

export default Location;

import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { getCashTransactions, cash_deposit, cash_withdrawal, deleteTransaction } from '../../api';
import { useNotification } from '../../context/NotificationContext';
import {
  Box,
  CircularProgress,
  Container,
  Fab,
  Typography,
  Button,
} from '@mui/material';
import FormTransaction from './FormTransaction';
import { CASH_TRANSACTION } from '../../constants';
import MainCard from '../../utils/MainCard';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import TransactionList from '../../utils/TransactionList'; // Adjust the path as needed
import { UserContext } from '../../context/UserContext';

function Savings() {
  const { user, refreshUser } = useContext(UserContext);
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState(false); // State to manage the transaction form visibility
  const { showNotification } = useNotification();

  const fetchTransactions = async () => {
    try {
      if (!user?.id) throw new Error('User ID is not defined');
      const response = await getCashTransactions(user.id);
      return response.data;
    } catch (error) {
      console.error('Fetch Transactions Error:', error);
      throw new Error('Network response was not ok');
    }
  };

  const { data: transactions, error, isLoading, refetch } = useQuery(
    ['cashTransactions', user.id],
    fetchTransactions,
    {
      enabled: !!user.id, // Only run the query if the user ID is defined
    }
  );

  if (error) return <p>Error: {error.message}</p>;

  const handleEdit = (transactionId) => {
    console.log('Edit transaction', transactionId);
  };

  const handleDelete = async (transactionId) => {
    console.log('Delete transaction', transactionId);
    await deleteTransaction(transactionId);
    await refetch();
    showNotification('Transaction successfully deleted');
  };

  const handleNewTransaction = async (formData) => {
    try {
      formData.user_id = user.id;
      console.log('Transaction Form data', formData);

      if (formData.type === CASH_TRANSACTION.DEPOSIT) await cash_deposit(formData);
      else if (formData.type === CASH_TRANSACTION.WITHDRAW) await cash_withdrawal(formData);
      else throw new Error('Invalid transaction type');

      refetch(); // Refetch transactions after a new one is added
      refreshUser();
      showNotification('Cash operation successful');
      setIsAdding(false); // Close the form after submission
    } catch (error) {
      console.error('An error occurred while adding transaction:', error);
      showNotification('Cash operation failed : ' + error.response?.data?.detail || '');
    }
  };

  return (
    <Container maxWidth="lg">
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="center" my={0} sx={{ mb: 3 }}>
            <MainCard
              title={t('personal_contribution')}
              value={user.contributions}
              m_change={0}
              y_change={0}
              currency={user.currency}
              tooltip={t('tooltip_savings')}
              size="h4"
            />
          </Box>

          {transactions.length === 0 ? (
            <Box textAlign="center" mt={5}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setIsAdding(true)}
                sx={{ mt: 3 }}
              >
                {t('add_your_first_cash_transaction')}
              </Button>
            </Box>
          ) : (
            <TransactionList
              title={t('deposit_withdrawal')}
              transactions={transactions}
              currency={user.currency}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          )}
 {transactions.length !== 0 && (
          <Fab
            color="primary"
            aria-label={t('add_transaction')}
            onClick={() => setIsAdding(true)}
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
          >
            <AddIcon />
          </Fab>)}

          {isAdding && (
            <FormTransaction
              user={user}
              apply={handleNewTransaction}
              onClose={() => setIsAdding(false)}
              open={isAdding}
            />
          )}
        </>
      )}
    </Container>
  );
}

export default Savings;

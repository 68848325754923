import React from 'react';
import { Card, CardContent, Typography, Grid, Box, Button, Avatar, Rating, IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ConciergeCard = ({ concierge, onRequest, onEdit, onDelete, role }) => {
  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          {/* Concierge Avatar */}
          <Grid item xs={2}>
            <Avatar
              variant="rounded"
              src={concierge.logoUrl || '/placeholder.png'}
              alt={concierge.name}
              sx={{ width: 56, height: 56 }}
            />
          </Grid>

          {/* Concierge Details */}
          <Grid item xs={8} sx={{ ml: 2}}>
            <Typography variant="h6">{concierge.name}</Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Rating value={concierge.rating} precision={0.5} readOnly size="small" />
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {concierge.rating} ({concierge.reviewCount} reviews)
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2">{concierge.city}, {concierge.country}</Typography>
            <Typography variant="body2">{concierge.category}</Typography>
          </Grid>
        </Grid>

        {/* ROI, Asset Price, Fees, and Request Button */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Typography variant="subtitle2">{`th ROI: ${concierge.theoric_roi}%`}</Typography>
            <Typography variant="subtitle2">{`th Asset Price: ${concierge.theoric_asset_price} ${concierge.currency}`}</Typography>
            <Typography variant="subtitle2">{`Fees: ${concierge.fees}%`}</Typography>
            {role=="ADMIN" && <Typography variant="subtitle2">{`Code: ${concierge.code}`}</Typography>}
            <Typography variant="subtitle2">{`Description: ${concierge.description}`}</Typography>
          </Grid>

          {role=="INDIVIDUAL" && <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Button variant="contained" color="primary" onClick={() => onRequest(concierge)}>
              Request Service
            </Button>
          </Grid>}
        </Grid>

        {/* Admin Actions */}
        {role == "ADMIN" && (
          <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
            <Grid item>
              <IconButton color="primary" onClick={() => onEdit(concierge)}>
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="secondary" onClick={() => onDelete(concierge.id)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ConciergeCard;

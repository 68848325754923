import ApartmentIcon from '@mui/icons-material/Apartment';
import HouseIcon from '@mui/icons-material/House';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import BusinessIcon from '@mui/icons-material/Business';
import TerrainIcon from '@mui/icons-material/Terrain';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SavingsIcon from '@mui/icons-material/Savings';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import AgricultureIcon from '@mui/icons-material/Agriculture'; // This might not exist, use a placeholder if not available
import LocationCityIcon from '@mui/icons-material/LocationCity';
import FactoryIcon from '@mui/icons-material/Factory'; // This might not exist, use a placeholder if not available
import ElderlyIcon from '@mui/icons-material/Elderly';
import WorkIcon from '@mui/icons-material/Work';
import CollectionsIcon from '@mui/icons-material/Collections';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'; // Use for equipment
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import InventoryIcon from '@mui/icons-material/Inventory';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import buildings from '../resources/buildings.jpg';
import collectibles from '../resources/collectibles.jpeg';
import house from '../resources/house.jpeg';
import apartment from '../resources/apartment.jpeg';
import equipment from '../resources/equipment.jpeg';
import financial_product from '../resources/financial_product.jpeg';
import land from '../resources/land.jpeg';
import other from '../resources/other.jpeg';
import savings_account from '../resources/savings_account.jpeg';
import vehicule from '../resources/vehicule.jpeg';
import { purple, green, blue, yellow, indigo, orange,cyan, lime, teal, pink } from '@mui/material/colors';

const colorList = [
  blue[500],
  green[500],
  yellow[600],
  purple[500],
  orange[500],
  pink[500],
  indigo[500],
  teal[500],
  cyan[500],
  lime[500]
];
export const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colorList.length);
    return colorList[randomIndex];
  };

export const getCategoryIcon = (category) => {

    const iconMap = {
    'HOUSE': <HouseIcon />,
    'APARTMENT': <ApartmentIcon />,
    'APPARTMENT':<ApartmentIcon />,
    'BEDROOM': <HotelIcon />, // Assuming you use HotelIcon for bedrooms
    'OFFICE': <BusinessIcon />,
    'PARKING': <LocalParkingIcon />,
    'COMMERCIAL_LOCAL': <InventoryIcon />, // Assuming you use StoreIcon for commercial locales
    'ETF': <ShowChartIcon />,
    'STOCK': <ShowChartIcon />,
    'BOND': <AccountBalanceIcon />,
    'CRYPTOCURRENCY': <CurrencyBitcoinIcon />, // Assuming you use CurrencyBitcoinIcon for cryptocurrencies
    'SAVINGS_ACCOUNT': <SavingsIcon />,
    'CAR': <DirectionsCarIcon />,
    'BUS': <DirectionsBusIcon />,
    'MOTOCYCLE': <TwoWheelerIcon />,
    'LAND': <TerrainIcon />,
    'RESIDENTIAL_LAND': <LocationCityIcon />,
    'INDUSTRIAL_LOCAL': <FactoryIcon />,
    'RETIREMENT_ACCOUNT': <ElderlyIcon />,
    'PENSION_PLAN': <ElderlyIcon />,
    'ANTIQUES': <CollectionsIcon />,
    'RARE_COINS': <MonetizationOnIcon />,
    'STAMPS': <CollectionsIcon />,
    'PRECIOUS_METAL': <MonetizationOnIcon />,
    'EQUIPMENT': <PrecisionManufacturingIcon />,
    'OTHER_ASSET': <LocalActivityIcon />,
    // ... other cases ...
  };

  return iconMap[category] || <LocalActivityIcon />;


};


// Function to preload images
const preloadImages = () => {
  const convertToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  };

  const imageSources = {
    'REAL_ESTATE': buildings,
    'FINANCIAL_PRODUCT': financial_product,
    'EQUIPMENT': equipment,
    'AUTOMOBILE': vehicule,
    'RETIREMENT_SAVINGS': savings_account,
    'COLLECTIBLES': collectibles,
    'HOUSE': house,
    'APARTMENT': apartment,
    'LAND':land,
    'OTHER_ASSET': other,
  };
  const promises = Object.keys(imageSources).map(type =>
    convertToBase64(imageSources[type])
      .then(base64String => localStorage.setItem(`image_${type}`, base64String))
  );

  return Promise.all(promises);
};
export const preloadImagesAssetType = preloadImages();

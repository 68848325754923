import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useResetPassword } from 'react-supabase';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Button,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNotification } from '../../context/NotificationContext';

const Forgot = ({ toggleShow }) => {
  const { t } = useTranslation();
  const [{ error, fetching }, resetPassword] = useResetPassword({
    options: {
      redirectTo: process.env.REACT_APP_SITE_URL + "/profile/update"
    }
  });
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: "onChange",  // Validate the form on change
  });
  const { showNotification } = useNotification();

  const onSubmit = async (data) => {
    try {
      console.log('Email for password reset:', data.email);
      const { error } = await resetPassword(data.email);
      if (error) {
        showNotification('Error sending email');
      } else {
        showNotification('Password recovery process has been sent to your email');
      }
      toggleShow();
    } catch (error) {
      console.error('Failed to request password reset', error);
      showNotification('Failed to request password reset: ' + error.message);
    }
  };

  return (
    <Dialog open onClose={toggleShow} fullWidth maxWidth="sm">
      <DialogTitle>
        {t('forgotPassword')}
        <IconButton
          aria-label="close"
          onClick={toggleShow}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Typography>{t('forgot_password_instructions')}</Typography>
          <Controller
            name="email"
            control={control}
            rules={{ required: "Email is required", pattern: /^\S+@\S+$/i }}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('email')}
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleShow} color="secondary">
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isValid || fetching}
          >
            {t('submit')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Forgot;

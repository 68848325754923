import React, { createContext, useState, useEffect } from 'react';
import { getUser } from '../api';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const refreshUser = async () => {
    try {
      setLoading(true);
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser && storedUser.id) {
        const result = await getUser();
        if (JSON.stringify(storedUser) !== JSON.stringify(result.data)) {
          setUser(result.data);
        }
      } else {
        setUser(storedUser);
      }
      setError(null);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {

    refreshUser();

    const interval = setInterval(() => {
      console.info('Fetching user data...');
      refreshUser();
    }, 500000);

    return () => clearInterval(interval);
  }, []);



  const saveUser = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    console.debug('Save in local storage');
  };

  const logout = () => {
    console.debug('Logging out user');
    setUser(null);
    localStorage.removeItem('user');
  };

  return (
    <UserContext.Provider value={{ user, saveUser, logout, loading, error, refreshUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

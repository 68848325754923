import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

import DashboardPro from './DashboardPro';
import DashboardUser from './DashboardUser';


const Dashboard = () => {
  const { user } = useContext(UserContext);
  return (
       user.role === 'PRO' ? <DashboardPro user={user} /> : <DashboardUser user={user}/>
  );
};

export default Dashboard;

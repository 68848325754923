import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Card,
  CardContent,
  CardActions,
  Button,
  Chip,
  Divider,
  Typography
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardToInbox from '@mui/icons-material/CheckCircle';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';
import { currencySymbol } from '../../utils';

const TransactionDetails = ({ detail, user, partners }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1"><strong>{t('type')}:</strong> {t(detail.type)}</Typography>
      <Typography variant="subtitle1"><strong>{t('title')}:</strong> {detail.title}</Typography>
      <Typography variant="subtitle1"><strong>{t('category')}:</strong> {t(detail.category)}</Typography>
      <Typography variant="subtitle1"><strong>{t('amount')}:</strong> {detail.amount} {currencySymbol(user.currency)}</Typography>
      {detail.tags && <Typography variant="subtitle1"><strong>{t('tags')}:</strong> {detail.tags}</Typography>}
      {detail.partner_id && <Typography variant="subtitle1"><strong>{t('partner')}:</strong> {partners.find(p => p.id === detail.partner_id)?.name}</Typography>}
      {detail.occupancy && <Typography variant="subtitle1"><strong>{t('occupancy')}:</strong> {detail.occupancy}</Typography>}
      {detail.notes && <Typography variant="subtitle1"><strong>{t('notes')}:</strong> {detail.notes}</Typography>}
    </Box>
  );
};

const TransactionList = ({
  user,
  groupedTransactions,
  expanded,
  handleExpandClick,
  formatDate,
  statusLabels,
  statusColors,
  assets = [],  // Default to empty array to avoid undefined
  partners = [],  // Default to empty array to avoid undefined
  handleSubmitTransaction,
  openUpdateTransaction,
  handleDeleteTransaction,
  handleApproveTransaction,
  handleOpenRejectModal,
}) => {
  const { t } = useTranslation();

  return (
    <Box my={3}>
      <Typography variant="h6" sx={{ mb: 1 }}>{t('pending_transactions')}</Typography>
      {Object.entries(groupedTransactions).length > 0 ? (
        Object.entries(groupedTransactions).map(([ownerName, ownerTransactions]) => (
          <Box key={ownerName} sx={{ mb: 2 }}>
            {user.role === 'PRO' ? (
              <List>
                <ListItem button onClick={() => handleExpandClick(ownerName)}>
                  <ListItemText primary={`${ownerName} (${ownerTransactions.length})`} />
                  {expanded[ownerName] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expanded[ownerName]} timeout="auto" unmountOnExit>
                  {ownerTransactions.map((transaction) => {
                    const asset = assets.find(a => a.id === transaction.asset_id);
                    return (
                      <Card key={transaction.id} sx={{ my: 2 }}>
                        <CardContent>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                              <Typography variant="subtitle1"><strong>{t('asset')}:</strong> {asset?.name || t('unknown')}</Typography>
                              <Typography variant="subtitle1"><strong>{t('date')}:</strong> {formatDate(transaction.date)}</Typography>
                              {transaction.repeat_until && (
                                <Typography variant="subtitle1"><strong>{t('repeat_until')}:</strong> {formatDate(transaction.repeat_until)}</Typography>
                              )}
                              {transaction.notes && (
                                <Typography variant="subtitle1"><strong>{t('notes')}:</strong> {formatDate(transaction.notes)}</Typography>
                              )}
                            </Box>
                            <Chip
                              label={statusLabels[transaction.status]}
                              color={statusColors[transaction.status]}
                              variant="outlined"
                              sx={{ ml: 1 }}
                            />
                          </Box>
                          <Divider sx={{ my: 1 }} />
                          {transaction.details.map((detail, index) => (
                            <TransactionDetails key={index} detail={detail} user={user} partners={partners} />
                          ))}
                        </CardContent>
                        <CardActions>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            {transaction.status === "DRAFT" && <Button size="small" color="primary" startIcon={<ForwardToInbox />} onClick={() => handleSubmitTransaction(transaction.id)}>
                              {t('submitting')}
                            </Button>}
                            <Button size="small" color="secondary" startIcon={<EditIcon />} onClick={() => openUpdateTransaction(transaction)}>
                              {t('edit')}
                            </Button>
                            <Button color="error" size="small" startIcon={<DeleteIcon />} onClick={() => handleDeleteTransaction(transaction.id)}>
                              {transaction.status === "DRAFT" ? '' : t('delete')}
                            </Button>
                          </Box>
                        </CardActions>
                      </Card>
                    );
                  })}
                </Collapse>
              </List>
            ) : ( // user.role !== 'PRO'
              ownerTransactions.map((transaction) => {
                const asset = assets.find(a => a.id === transaction.asset_id);
                return (
                  <Card key={transaction.id} sx={{ my: 2 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                          <Typography variant="subtitle1"><strong>{t('asset')}:</strong> {asset?.name || t('unknown')}</Typography>
                          <Typography variant="subtitle1"><strong>{t('date')}:</strong> {formatDate(transaction.date)}</Typography>
                          {transaction.repeat_until && (
                            <Typography variant="subtitle1"><strong>{t('repeat_until')}:</strong> {formatDate(transaction.repeat_until)}</Typography>
                          )}
                        </Box>
                        <Chip
                          label={statusLabels[transaction.status]}
                          color={statusColors[transaction.status]}
                          variant="outlined"
                          sx={{ ml: 1 }}
                        />
                      </Box>
                      <Divider sx={{ my: 1 }} />
                      {transaction.details.map((detail, index) => (
                        <TransactionDetails key={index} detail={detail} user={user} partners={partners} />
                      ))}
                    </CardContent>
                    <CardActions>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button size="small" sx={{ mr: 1 }} color="success" startIcon={<DoneIcon />}
                          onClick={() => handleApproveTransaction(transaction.id)}>
                          {t('approve')}
                        </Button>
                        <Button size="small" color="warning" startIcon={<ThumbDownIcon />}
                          onClick={() => handleOpenRejectModal(transaction.id)}>
                          {t('reject')}
                        </Button>
                      </Box>
                    </CardActions>
                  </Card>
                );
              })
            )}
          </Box>
        ))
      ) : (
        <Typography variant="body2">{t('no_transaction')}</Typography>
      )}
    </Box >
  );
};

export default TransactionList;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Paper,
  InputAdornment,
  Modal
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { configExpenseTypes, configIncomeTypes } from '../../api';
import { createAmountChangeHandler, currencySymbol } from '../../utils';

const FormTransaction = ({ user, apply, toggleShow, initialData }) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [formattedAmount, setFormattedAmount] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch
  } = useForm({
    defaultValues: {
      date: initialData?.date || new Date().toISOString().split('T')[0],
      type: initialData?.type || '',
      category: initialData?.category || '',
      description: initialData?.description || '',
      amount: initialData?.amount ? Math.abs(initialData.amount).toString() : '',
      notes: initialData?.notes || '',
      tags: initialData?.tags?.join(', ') || ''
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (initialData) {
      handleTypeChange(initialData.type);
    }
  }, [initialData]);

  const onSubmitHandler = (data) => {
    if (!isNaN(data.amount)) {
      const dataToSend = {
        ...data,
        user_id: user.id,
        amount: Math.abs(parseFloat(data.amount)),
        tags: data.tags.split(',').map(tag => tag.trim())
      };
      apply(dataToSend);
      toggleShow();
    }
  };

  const handleTypeChange = (value) => {
    setValue('type', value);
    if (value === 'INCOME') {
      configIncomeTypes()
        .then(response => {
          setCategories(response.data);
          setValue('category', response.data[0]);
        })
        .catch(console.error);
    } else if (value === 'EXPENSE') {
      configExpenseTypes()
        .then(response => {
          setCategories(response.data);
          setValue('category', response.data[0]);
        })
        .catch(console.error);
    } else {
      setCategories([]);
    }
  };

  const handleAmountChange = createAmountChangeHandler(setFormattedAmount, user.currency);
  const type = watch('type');

  return (
    <Modal open={true} onClose={toggleShow}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
        <Paper sx={{ padding: 2, minWidth: 300 }}>
          <Typography variant="h6" sx={{ m: 1 }}>{t(initialData ? 'edit_transaction' : 'add_transaction')}</Typography>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('date')}
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel>{t('type')}</InputLabel>
                      <Select
                        {...field}
                        label={t('type')}
                        onChange={(e) => {
                          field.onChange(e);
                          handleTypeChange(e.target.value);
                        }}
                      >
                        <MenuItem value="" disabled>{t('select_type')}</MenuItem>
                        <MenuItem value="INCOME">{t('INCOME')}</MenuItem>
                        <MenuItem value="EXPENSE">{t('EXPENSE')}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.type && <Typography color="error">{errors.type.message}</Typography>}
              </Grid>

              {(type === 'INCOME' || type === 'EXPENSE') && (
                <Grid item xs={12}>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel>{t('category')}</InputLabel>
                        <Select
                          {...field}
                          label={t('category')}
                        >
                          <MenuItem value="" disabled>{t('select_cat')}</MenuItem>
                          {categories.map((category) => (
                            <MenuItem key={category} value={category}>{t(category)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  {errors.category && <Typography color="error">{errors.category.message}</Typography>}
                </Grid>
              )}

              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: `${t('title_required')}` }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('title')}
                      fullWidth
                    />
                  )}
                />
                {errors.description && <Typography color="error">{errors.description.message}</Typography>}
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="amount"
                  control={control}
                  rules={{ required: `${t('amount_required')}`, validate: value => value > 0 || `${t('amount_must_be_positive')}` }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('amount')}
                      type="number"
                      fullWidth
                      value={field.value}
                      onChange={(e) => {
                        const value = Math.abs(e.target.value);
                        setFormattedAmount(value);
                        field.onChange(value);
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
                      }}
                    />
                  )}
                />
                {errors.amount && <Typography color="error">{errors.amount.message}</Typography>}
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('tags')}
                      fullWidth
                      placeholder={t('enter_tags_comma_separated')}
                    />
                  )}
                />
                {errors.tags && <Typography color="error">{errors.tags.message}</Typography>}
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="notes"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('notes')}
                      fullWidth
                      multiline
                      rows={2}
                    />
                  )}
                />
                {errors.notes && <Typography color="error">{errors.notes.message}</Typography>}
              </Grid>
            </Grid>

            <Box mt={2} display="flex" justifyContent="flex-start">
              <Button
                variant="outlined"
                color="warning"
                startIcon={<CloseIcon />}
                onClick={toggleShow}
                sx={{ mr: 1 }}>{t('cancel')}</Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                startIcon={<CheckIcon />}
                disabled={!isValid}
              >
                {t('submit')}
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </Modal>
  );
};

export default FormTransaction;

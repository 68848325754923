import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, CardHeader, Avatar, Button, Collapse, IconButton, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils';
import ProgressBar from 'react-bootstrap/ProgressBar';
import TransactionList from '../../utils/TransactionList';
import FormRepayment from './FormRepayment';
import { useQuery } from 'react-query';
import { getDebtTransactions, createRepayment, deleteDebt } from '../../api';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../utils/ConfirmDialog';
import { useNotification } from '../../context/NotificationContext';

const DebtCard = ({ debt, currency, user, refetch }) => {
  const { t } = useTranslation();
  const [showTransactions, setShowTransactions] = useState(false);
  const [showAddTransaction, setShowAddTransaction] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { showNotification } = useNotification();

  const { data: transactions, isLoading: transactionsLoading, refetch: refetchTransactions } = useQuery(
    ['transactions', debt.id],
    async () => {
      const response = await getDebtTransactions(debt.id);
      return response.data;
    },
    {
      enabled: showTransactions, // Only fetch transactions when the list is expanded
      refetchOnWindowFocus: false, // Avoid refetching when the window regains focus
    }
  );

  const handleAddRepayment = async (formData) => {
    try {
      formData['amount_opt'] = formData['interest_amount'];
      await createRepayment(debt.id, formData);
      refetch();
      refetchTransactions(); // Refetch transactions after adding a repayment
      showNotification(t('transaction_created_successfully'), 'success');
    } catch (error) {
      console.error('An error occurred while adding loan transaction:', error);
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    }
  };

  const handleDeletionConfirmation = () => {
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteDebt(debt.id);
      setIsDialogOpen(false);
      refetch();
      showNotification(t('debt_deleted_successfully'), 'success');
    } catch (error) {
      console.error('An error occurred while deleting the debt:', error);
      showNotification(t('debt_deletion_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsDeleting(false);
    }
  };

  const toggleTransactions = () => {
    setShowTransactions((prev) => !prev);
  };

  const toggleAddTransaction = () => {
    setShowAddTransaction((prev) => !prev);
  };

  const progress = (debt.paid / debt.principal_amount) * 100;

  return (
    <Card sx={{ mb: 2 }} variant="outlined">
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'red' }} aria-label="debt-lender">
            {debt.lender.substring(0, 3)}
          </Avatar>
        }
        title={debt.description}
        subheader={t(debt.type)}
      />

      <CardContent>
        <Typography variant="body2">
          {t('lender')}: {debt.lender}
        </Typography>
        <Typography variant="body2">
          {t('start_date')}: {debt.date}
        </Typography>
        <Typography variant="body2">
          {t('end_date')}: {debt.end_date}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">{t('principal_amount')}: </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {formatAmount(debt.principal_amount, currency)}
          </Typography>
        </Box>
        <Typography variant="body2">
          {t('interest_rate')}: {debt.interest_rate}%
        </Typography>
        <Typography variant="body2">
          {t('monthly_repayment')}: {formatAmount(debt.monthly_repayment, currency)}
        </Typography>
        <ProgressBar
          now={progress}
          label={`${progress.toFixed(2)}%`}
          variant="success"
          style={{
            margin: 'auto',
            display: 'block',
            height: '20px',
            borderRadius: '10px',
          }}
        />
        <Box display="flex" justifyContent="space-between" marginTop="10px">
          <Typography variant="body2">{t('paid')}</Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'green' }}>
            {formatAmount(debt.paid, currency)} ({progress.toFixed(2)}%)
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">{t('interest_paid')}</Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'red' }}>
            {formatAmount(debt.interest_paid, currency)}
          </Typography>
        </Box>
      </CardContent>

      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleAddTransaction}
            disabled={!debt.active}
            sx={{ flexGrow: 1, mr: 1 }}
          >
            {t('add_transaction')}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={toggleTransactions}
            sx={{ flexGrow: 1 }}
          >
            {showTransactions ? t('hide_transactions') : t('view_transactions')}
          </Button>
        </Box>

        <Collapse in={showAddTransaction}>
          {showAddTransaction && (
            <Box mt={2}>
              <FormRepayment debt={debt} user={user} apply={handleAddRepayment} toggleShow={toggleAddTransaction} />
            </Box>
          )}
        </Collapse>

        <Button
          startIcon={isDeleting ? <CircularProgress size={20} /> : <DeleteIcon />}
          variant="outlined"
          color="error"
          onClick={handleDeletionConfirmation}
          fullWidth
          sx={{ mt: 2 }}
          disabled={isDeleting}
        >
          {t('delete_debt')}
        </Button>
      </CardContent>

      <Collapse in={showTransactions}>
        <CardContent>
          {transactionsLoading ? (
            <CircularProgress />
          ) : (
            transactions && transactions.length > 0 ? (
              <TransactionList
                title={t('transactions')}
                transactions={transactions}
                currency={user.currency}
                onEdit={(transaction) => console.log('Edit transaction', transaction)}
                onDelete={(transaction) => console.log('Delete transaction', transaction)}
                readOnly={!debt.active}
              />
            ) : (
              <Typography variant="body2">{t('no_transactions')}</Typography>
            )
          )}
        </CardContent>
      </Collapse>

      <ConfirmDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        message={t('confirm_debt_deletion')}
      />
    </Card>
  );
};

export default DebtCard;

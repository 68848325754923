import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Box, CircularProgress, Container, Grid, Typography, List, ListItem, ListItemText, Collapse
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MainCard from '../../utils/MainCard';
import { getManagedAssets, getOwners } from '../../api';
import { useTranslation } from 'react-i18next';
import AssetCard from '../../utils/AssetCard'; // Assuming AssetCard is moved to its own file
import { formatAmount } from '../../utils';
import { useNotification } from '../../context/NotificationContext'; // Import the notification hook


const AssetsPro = ({user}) => {
  const { t } = useTranslation();
  const [groupedAssets, setGroupedAssets] = useState({});
  const [totalValue, setTotalValue] = useState(0);
  const [expanded, setExpanded] = useState({});
  const [ownerMapping, setOwnerMapping] = useState({});
  const { showNotification } = useNotification();

  const navigate = useNavigate();

  const { isLoading } = useQuery(
    ['assets', user?.id],
    () => getManagedAssets(),
    {
      enabled: !!user?.id,
      onSuccess: (response) => {
        const { data } = response;

        if (Array.isArray(data)) {
          const grouped = data.reduce((acc, asset) => {
            const clientId = asset.user_id;
            acc[clientId] = acc[clientId] || { items: [], totalValue: 0 };
            acc[clientId].items.push(asset);
            acc[clientId].totalValue += asset.value;
            return acc;
          }, {});

          setGroupedAssets(grouped);
          setTotalValue(data.reduce((sum, asset) => sum + asset.value, 0));
        } else {
          console.error('Expected an array, but received:', data);
          showNotification(t('failed_retrieving_assets_data') + ': ' + data, 'error');
        }
      },
    }
  );

  const { data: owners, isLoading: ownersLoading } = useQuery(
    ['owners', user?.id],
    () => getOwners(user?.id),
    {
      enabled: !!user?.id,
      onSuccess: (response) => {
        const ownerMap = response.data.reduce((acc, owner) => {
          acc[owner.id] = `${owner.name} ${owner.surname}`;
          return acc;
        }, {});
        setOwnerMapping(ownerMap);
      },
    }
  );

  const handleExpandClick = (clientId) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [clientId]: !prevExpanded[clientId],
    }));
  };

  return (
    <Container maxWidth="md">
      {(isLoading || ownersLoading) ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {Object.keys(groupedAssets).length === 0 ? (
            <Box textAlign="center" mt={5}>
              <Typography variant="h6" gutterBottom>
                {t('no_managed_assets')}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {t('no_managed_assets_prompt')}
              </Typography>
            </Box>
          ) : (
            <>
              <Box display="flex" justifyContent="center" my={0}>
                <MainCard
                  title={t('assets_total_value')}
                  value={totalValue}
                  currency={user.currency}
                  size='h4'
                  m_change={0} // Month change
                    y_change={0} // Year change
                    simplify={true}
                />
              </Box>

              {Object.entries(groupedAssets).map(([clientId, assets]) => (
                <List key={clientId}>
                  <ListItem button onClick={() => handleExpandClick(clientId)}>
                    <ListItemText primary={`${ownerMapping[clientId] || clientId} (${assets.items.length}) - ${formatAmount(assets.totalValue, user.currency, true)}`} />
                    {expanded[clientId] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={expanded[clientId]} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                      {assets.items.map((asset) => (
                        <AssetCard
                          key={asset.id}
                          asset={asset}
                          onOpen={() => navigate(`/assets/${asset.id}`)}
                          currency={user.currency}
                          readOnly={true}
                        />
                      ))}
                    </Grid>
                  </Collapse>
                </List>
              ))}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default AssetsPro;

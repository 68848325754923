import React from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatAmount } from '../../utils';
import { getUserCashflowDiagram } from '../../api';
import { useQuery } from 'react-query';
import { CircularProgress } from '@mui/material';

const CashflowDiagram = ({ currency }) => {
  const { t } = useTranslation();
  // Fetch cashflow data
  const { data: cashflowResult, isLoading, error } = useQuery('cashflowResult', getUserCashflowDiagram, {
    onSuccess: data => {
      console.log('CashflowResult data fetched successfully:', data);
    }
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Validate chaflow contains data


  const prepareChartData = () => {
    if (!cashflowResult || Object.keys(cashflowResult).length === 0) {
      return { categories: [], incomeData: [], expenseData: [], cashflowData: [] };
    }
    const cashflowdata = cashflowResult.data;

    const categories = Object.keys(cashflowdata);
    const incomeData = categories.map(date => cashflowdata[date].income || 0);
    const expenseData = categories.map(date => cashflowdata[date].expense || 0);
    const cashflowData = incomeData.map((income, index) => income - expenseData[index]);
    return { categories, incomeData, expenseData, cashflowData };
  };

  const { categories, incomeData, expenseData, cashflowData } = prepareChartData();

  const options = {
    chart: {
      type: 'column',
      zoomType: 'x',
      backgroundColor: 'transparent',
      height: 450,
      panning: true,
      panKey: 'shift',
    },
    title: null,
    xAxis: {
      categories, // Set x-axis categories directly
      tickmarkPlacement: 'on',
      labels: {
        rotation: -45,
        style: {
          fontSize: '13px',
          fontFamily: 'Arial, sans-serif',
        },
        formatter: function () {
          return this.value; // Display categories correctly
        },
      },
    },
    yAxis: {
      title: null,
      labels: {
        formatter: function () {
          return formatAmount(this.value, currency, true);
        },
      },
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        name: t('income'),
        data: incomeData,
        color: Highcharts.getOptions().colors[0], // Adjust color as needed
      },
      {
        name: t('expense'),
        data: expenseData,
        color: Highcharts.getOptions().colors[7], // Adjust color as needed
      },
      {
        name: t('cashflow'),
        type: 'spline',
        data: cashflowData,
        lineColor: Highcharts.getOptions().colors[2], // Adjust color as needed
        color: Highcharts.getOptions().colors[4], // Adjust color as needed
        fillOpacity: 0.1,
        visible: false,
        tooltip: {
          valueSuffix: ` ${currency}`,
        },
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        let tooltipHtml = `<b>${this.x}</b><br/>`;
        this.points.forEach(point => {
          tooltipHtml += `${point.series.name}: ${formatAmount(point.y, currency, true)}<br/>`;
        });
        return tooltipHtml;
      },
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          chart: {
            scrollablePlotArea: {
              minWidth: 300, // Adjust for desired mobile display width
              scrollPositionX: 1,
            },
          },
        },
      }],
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default CashflowDiagram;

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../api';
import { UserContext } from '../../context/UserContext';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ProfileEdit from '../profile/FormProfile';
import Forgot from './Forgot';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSignIn } from 'react-supabase';
import { Avatar } from '@mui/material';
import {
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Link,
  Dialog,
  DialogContent,
  DialogActions
} from '@mui/material';
import logo from '../../logo192.png'; // Adjust the path to your logo image

const Login = () => {
  const { saveUser } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [{ session }, signIn] = useSignIn();

  const [basicModal, setBasicModal] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);
  const toggleForgot = () => setForgotModal(!forgotModal);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalidEmail'))
      .required(t('emailRequired')),
    password: yup
      .string()
      .min(5, t('passwordTooShort'))
      .required(t('passwordRequired'))
  });

  const { control, handleSubmit, setError, formState } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const { errors, isSubmitting } = formState;

  const onSubmit = async (data) => {
    try {
      const { error, session } = await signIn({
        email: data.email,
        password: data.password,
      });

      if (error) throw error;

      const user_id = session.user.id;
      const token = session.access_token;
      try {
        const loginResponse = await loginUser({ user_id }, token);
        if (loginResponse.error) throw loginResponse.error;
        // clear old token
        localStorage.clear()
        // Save the token to localStorage
        localStorage.setItem('token', token);
        saveUser(loginResponse.data);
        navigate('/dashboard');
      } catch (err) {
        toggleShow();
      }
    } catch (err) {
      setError("apiError", { message: err.message || t('apiError', { error: err.message }) });
    }
  };

  const handleContactClick = () => {
    window.open('https://wa.me/message/ZTZUNNZHD4JRM1', '_blank');
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Card variant="outlined" sx={{ width: '100%', padding: '20px', boxShadow: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              padding: '16px',
              mb: 1,
            }}
          >
            <Avatar src={logo} alt="logo" sx={{ width: 56, height: 56, mr: 2 }} />
            <Typography variant="h7" align="center">
              {t('Gérer vos actifs intelligemment.')}
            </Typography>
          </Box>
          <CardContent>
            {errors.apiError && (
              <Typography color="error" align="center" sx={{ mb: 1 }}>{errors.apiError.message}</Typography>
            )}
            <Typography variant="subtitle2" align="center">
              {t('Connexion avec')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('email')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('password')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="password"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              />
              <Grid container justifyContent="flex-end" sx={{ mb: 1 }}>
                <Grid item>
                  <Link
                    onClick={toggleForgot}
                    sx={{ cursor: 'pointer', textTransform: 'none', fontSize: '0.875rem', color: 'primary.main' }}
                  >
                    {t('forgotPassword')}
                  </Link>
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 0, mb: 2 }}
                disabled={isSubmitting}
                type="submit"
              >
                {t('login')}
              </Button>
            </form>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="body2">
                  {t('no_account')}
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ textTransform: 'none', fontSize: '0.875rem', padding: '6px 12px' }}
                  disabled={isSubmitting}
                  onClick={() => navigate('/signup')}
                >
                  {t('signup')}
                </Button>
                <Button
                variant="text"
                color="primary"
                sx={{ textTransform: 'none' }}
                onClick={handleContactClick}
              >
                {t('contact_us')}
              </Button>
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Box>
      <Dialog open={basicModal} onClose={toggleShow} fullWidth maxWidth="sm">
        <ProfileEdit
          user={{ id: session?.user?.id, name: '', surname: '', currency: 'EUR', email: session?.user?.email, language: 'fr' }}
          saveUser={saveUser}
          open={basicModal}
          onClose={toggleShow}
          onSuccess={() => navigate('/dashboard')}
        />
      </Dialog>
      <Dialog open={forgotModal} onClose={toggleForgot} fullWidth maxWidth="sm">
        <DialogContent>
          <Forgot toggleShow={toggleForgot} />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleForgot} color="secondary">
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Login;

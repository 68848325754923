import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { preloadImagesAssetType } from '../../../../utils/Avatar';
import { Grid, Typography, Card, CardActionArea, CardContent, CardMedia, CircularProgress } from '@mui/material';
import { configAssetTypes } from '../../../../api';

const AssetTypeSelector = memo(({ onSelect }) => {
  const { t } = useTranslation();
  const [assetTypes, setTypes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    configAssetTypes()
      .then((response) => {
        setTypes(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to load asset types');
        setLoading(false);
      });
  }, []);

  const handleTypeSelect = (type) => {
    onSelect(type); // This will pass the selected type back to the wizard
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }
  const getImageSrc = (type) => localStorage.getItem(`image_${type}`) || preloadImagesAssetType[type];

  return (
    <Grid container spacing={2}>
      {assetTypes.map((type) => (
        <Grid item xs={6} sm={6} md={6} lg={6} key={type}>
        <Card
          sx={{
            height: 160,
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            '&:hover': { boxShadow: '0 6px 12px rgba(0,0,0,0.2)', transform: 'scale(1.02)' },
          }}
          onClick={() => handleTypeSelect(type)}
        >
            <CardActionArea sx={{ position: 'relative', height: '100%' }}>
              <CardMedia
                component="img"
                sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                image={getImageSrc(type)}
                background='black'
                alt={t(type)}
              />
              <CardContent sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)',
                width: '100%',
                padding: 1,
              }}>
                <Typography gutterBottom variant="h5" color= 'white'>
                  {t(type)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
});

export default AssetTypeSelector;

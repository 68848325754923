import ClickableTooltip from '../utils/ClickableTooltip';
import { Card, CardContent, Typography } from '@mui/material';
import { formatAmount } from '../utils';

const MetricCard = ({ t, title, value, currency, tooltip, size, simplify = false }) => {

  // Check if value is null or undefined and set displayValue accordingly
  let displayValue = 'N/A';
  let suffix = null;
  let color = null;
  if (value !== null && value !== undefined && !isNaN(value)) {
    color = value >= 0 ? 'green' : 'red';
    if (currency) {
      displayValue = simplify ? formatAmount(value, currency, true, false) : formatAmount(value, currency);
    } else {
      displayValue = `${(value * 100).toFixed(2)}%`;
    }
  }
  return (
    <Card sx={{
      backgroundColor: 'rgba(0, 0, 0, 0.03)', borderRadius: 1,
      justifyContent: 'center'
    }}>
      <CardContent >
        <ClickableTooltip title={t(tooltip)}>
          <Typography color="textSecondary" variant="subtitle2" >
            {t(title)}
          </Typography>
        </ClickableTooltip>
        <Typography variant={size} component="span" style={{ fontWeight: 'bold', color: color }}>
          {displayValue}
          {suffix && <span>{suffix}</span>}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MetricCard;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useClient } from 'react-supabase';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Button,
  Typography
} from '@mui/material';
import { Avatar } from '@mui/material';
import logo from '../../logo192.png';
import { useNotification } from '../../context/NotificationContext';

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useClient();
  const { showNotification } = useNotification();


  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
  });

  const { control, handleSubmit, setError, formState, reset } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const { errors, isSubmitting } = formState;

  const onSubmit = async (data) => {
    try {
        //const { signUp } = useSignUp();
        console.log(data)
        const { error } = await client.auth.signUp(
          {
            email: data.email,
            password: data.password,
          },
          { redirectTo: process.env.REACT_APP_SITE_URL+"/login"}

        )

        if (error) {
          console.error("Error during sign up: ", error.message);
          setError("signUp", { message: error.message });
        } else {
          showNotification(t('check_email'));
          reset(); // Clear the form
          navigate('/login');
        }
      } catch (err) {
        console.error("Error during sign up: ", err);
        setError("signUp", { message: err.message });
      }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Card variant="outlined" sx={{ my: 3, p: 3 }}>
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              padding: '16px',
              mb: 1,
            }}
          >
            <Avatar src={logo} alt="logo" sx={{ width: 56, height: 56, mr: 2 }} />
            <Typography variant="h7" align="center">
              {t('login_text')}
            </Typography>
          </Box>
          <CardContent>
          <Typography variant="subtitle2" align="center">
              {t('signup_with')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('email')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('password')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type='password'
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('confirmPassword')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type='password'
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                  />
                )}
              />

              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled={isSubmitting}
                type="submit"
              >
                {t('signup')}
              </Button>
            </form>
          </CardContent>
        </Card>
        <Typography variant="body2" align="center" sx={{ mt: 0 }}>
        {t('already_account')}
          <Button
            sx={{ textTransform: 'none', ml: 1 }}
            color="primary"
            onClick={() => navigate('/login')} // Adjust this line as per your route config
          >
            {t('login')}
          </Button>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignUp;

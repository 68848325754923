import React from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getAssets } from '../../api';
import { useQuery } from 'react-query';
import { CircularProgress } from '@mui/material';

Highcharts.setOptions({
  colors: Highcharts.getOptions().colors.map(color => ({
    radialGradient: {
      cx: 0.5,
      cy: 0.3,
      r: 0.7
    },
    stops: [
      [0, color],
      [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
    ]
  }))
});

const AssetAllocPie = ({ currency, cash_balance }) => {
  const { t } = useTranslation();
  const { data: assetList, isLoading, error } = useQuery(
    'assetList',    // The query key remains simple since we always fetch active assets
    () => getAssets(true),
    {
      onSuccess: data => {
        console.log('Assets fetched successfully:', data);
      },
      onError: err => {
        console.error('Error fetching assets:', err);
      }
    }
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (assetList === null || assetList.data === null) {
    return null;
  }

  const generateChartData = () => {
    const assetAllocation = {};

    // Add liquidity to the allocation
    assetAllocation[t('liquidity')] = {
      value: cash_balance,
      currency: currency,
    };

    // Process assets if any
    assetList.data?.forEach((asset) => {
      const type = t(asset.type);
      const value = asset.value;

      if (!assetAllocation[type]) {
        assetAllocation[type] = {
          value: 0,
          currency: currency,
        };
      }

      assetAllocation[type].value += value;
    });

    // Calculate total value
    const totalValue = Object.values(assetAllocation).reduce((total, type) => total + type.value, 0);

    console.log("assetAllocation ", assetAllocation);
    // Generate chart data with percentages
    return Object.keys(assetAllocation).map(type => ({
      name: type,
      y: (assetAllocation[type].value / totalValue) * 100, // Calculate the percentage
      currency: assetAllocation[type].currency,
    }));
  };

  const chartData = generateChartData();

  const options = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      reflow: true,
      plotShadow: false,
    },
    title: null,
    tooltip: {
      pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
      }
    },
    legend: {
      enabled: true
    },
    series: [
      {
        name: t('allocation'),
        data: chartData,
      },
    ],
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  );
};

export default AssetAllocPie;

import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import SidebarPro from './SidebarPro';
import SidebarUser from './SidebarUser';
import SidebarAdmin from './SidebarAdmin';

const Sidebar = ({ isMobile, isVisible, toggleSidebar }) => {
  const { user } = useContext(UserContext);

  if (!user) return null; // Handle the case where user might not be loaded yet

  if (user.role === 'PRO') {
    return <SidebarPro isMobile={isMobile} isVisible={isVisible} toggleSidebar={toggleSidebar} />;
  } else if (user.role === 'ADMIN') {
    return <SidebarAdmin isMobile={isMobile} isVisible={isVisible} toggleSidebar={toggleSidebar} />;
  } else {
    return <SidebarUser isMobile={isMobile} isVisible={isVisible} toggleSidebar={toggleSidebar} />;
  }
};

export default Sidebar;

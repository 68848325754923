import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { configCurrencies } from '../../api';
import { useNotification } from '../../context/NotificationContext'; // Import the notification hook


const ConciergeForm = ({ user, apply, toggleShow }) => {
  const { t } = useTranslation();
  const [currencies, setCurrencies] = useState([]);
  const { showNotification } = useNotification();

  const generateCode = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const length = Math.floor(Math.random() * 3) + 5; // length between 5 and 7
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    defaultValues: {
      name: '',
      category: '',
      contact: { phone: '', email: '' },
      address: '',
      country: '',
      city: '',
      theoric_roi: '',
      theoric_asset_price: '',
      fees: '',
      plan_expiry_date: '',
      description: '',
      notes: '',
      currency: '',
      code: generateCode()
    },
    mode: "onChange",
  });

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const res = await configCurrencies();
        setCurrencies(res.data);
      } catch (error) {
        console.error('Failed to fetch currencies', error);
      }
    };
    fetchCurrencies();
  }, [user.currency]);

  const onSubmit = async (data) => {
    try {
      await apply(data);
      toggleShow();
    } catch (error) {
      console.error('Failed to create concierge:', error);
      showNotification(t('failed_create_concierge') + ': ' + error.message, 'error');

    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('name')}
                  error={!!errors.name}
                  helperText={errors.name ? t('concierge_name_required') : ''}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('category')}
                  error={!!errors.category}
                  helperText={errors.category ? t('category_required') : ''}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="contact.phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('phone')}
                  error={!!errors.contact?.phone}
                  helperText={errors.contact?.phone ? t('phone_required') : ''}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="contact.email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('email')}
                  error={!!errors.contact?.email}
                  helperText={errors.contact?.email ? t('email_required') : ''}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('address')}
                  error={!!errors.address}
                  helperText={errors.address ? t('address_required') : ''}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('country')}
                  error={!!errors.country}
                  helperText={errors.country ? t('country_required') : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('city')}
                  error={!!errors.city}
                  helperText={errors.city ? t('city_required') : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="theoric_roi"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('theoric_roi')}
                  type="number"
                  error={!!errors.theoric_roi}
                  helperText={errors.theoric_roi ? t('theoric_roi_required') : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="theoric_asset_price"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('theoric_asset_price')}
                  type="number"
                  error={!!errors.theoric_asset_price}
                  helperText={errors.theoric_asset_price ? t('theoric_asset_price_required') : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="fees"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('fees')}
                  type="number"
                  error={!!errors.fees}
                  helperText={errors.fees ? t('fees_required') : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="plan_expiry_date"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('plan_expiry_date')}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.plan_expiry_date}
                  helperText={errors.plan_expiry_date ? t('plan_expiry_date_required') : ''}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('description')}
                  multiline
                  rows={3}
                  error={!!errors.description}
                  helperText={errors.description ? t('description_required') : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="dense">
              <InputLabel>{t('currency')}</InputLabel>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Select {...field} label={t('currency')} required>
                    {currencies.map((curr) => (
                      <MenuItem key={curr} value={curr}>{curr}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('code')}
                  error={!!errors.code}
                  helperText={errors.code ? t('code_required') : ''}
                  required
                  value={field.value}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleShow} color="secondary">
          {t('cancel')}
        </Button>
        <Button color="primary" type="submit" disabled={!isValid}>
          {t('save')}
        </Button>
      </DialogActions>
    </form>
  );
};

export default ConciergeForm;

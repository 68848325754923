import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import CloseButton from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {
  Dialog, Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { currencySymbol } from '../../utils';
import { configDebtTypes } from '../../api';

const FormDebt = ({ user, apply, open, onClose }) => {
  const { t } = useTranslation();
  const [debtTypes, setTypes] = useState([]);
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      date: new Date().toISOString().split('T')[0],
      type: '',
      end_date: new Date().toISOString().split('T')[0],
      lender: '',
      principal_amount: '',
      interest_rate: '',
      monthly_repayment: '',
      description: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (user && user.id) {
      configDebtTypes()
        .then((response) => {
          setTypes(response.data);
          setValue('type', response.data[0]); // Set default debt type
        })
        .catch(console.error);
    }
  }, [user, setValue]);

  const onSubmitHandler = (data) => {
    apply(data);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('add_debt')}</DialogTitle>

      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('start_date')}
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.date}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="end_date"
                control={control}
                rules={{
                  validate: {
                    isValidEndDate: (value) => {
                      const startDateValue = watch('date');
                      return new Date(value) > new Date(startDateValue) || t('end_date_must_be_after_start_date');
                    },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('end_date')}
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.end_date}
                    helperText={errors.end_date ? errors.end_date.message : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('type')}
                    select
                    fullWidth
                    error={!!errors.type}
                  >
                    {debtTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {t(type)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="lender"
                control={control}
                rules={{ required: t('lender_required') }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('lender')}
                    fullWidth
                    error={!!errors.lender}
                    helperText={errors.lender ? errors.lender.message : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="principal_amount"
                control={control}
                rules={{
                  required: t('principal_amount_required'),
                  validate: (value) => value > 0 || t('principal_amount_must_be_positive'),
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('principal_amount')}
                    type="number"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {currencySymbol(user.currency)}
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.principal_amount}
                    helperText={errors.principal_amount ? errors.principal_amount.message : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="monthly_repayment"
                control={control}
                rules={{
                  validate: {
                    isValidRepayment: (value) => {
                      const principalAmountValue = watch('principal_amount');
                      return (
                        (Number(value) > 0 && Number(value) < Number(principalAmountValue)) ||
                        t('repayment_must_be_less_than_principal')
                      );
                    },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('monthly_repayment')}
                    type="number"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {currencySymbol(user.currency)}
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.monthly_repayment}
                    helperText={errors.monthly_repayment ? errors.monthly_repayment.message : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="interest_rate"
                control={control}
                rules={{
                  required: t('interest_rate_required'),
                  validate: {
                    isValidFormat: (value) => {
                      const regex = /^(100(\.00?)?|(\d{1,2})(\.\d{1,2})?)$/;
                      return regex.test(value) || t('interest_rate_must_be_between_0_and_100');
                    },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={`${t('interest_rate')} (%)`}
                    type="text"
                    fullWidth
                    error={!!errors.interest_rate}
                    helperText={errors.interest_rate ? errors.interest_rate.message : ''}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (!isNaN(value) && value.includes('.')) {
                        value = value.match(/^-?\d*(\.\d{0,2})?/)[0];
                      }
                      field.onChange(value);
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                rules={{ required: t('description_required') }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('title')}
                    fullWidth
                    error={!!errors.description}
                    helperText={errors.description ? errors.description.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: theme.palette.background.paper, paddingTop: 1 }}>
            <Button variant="outlined"
              color="warning"
              startIcon={<CloseButton />}
              onClick={onClose}
              sx={{ mr: 1 }}>{t('cancel')}</Button>

            <Button
              variant="outlined"
              color="primary"
              type="submit"
              startIcon={<CheckIcon />}
              disabled={!isValid}
            >
              {t('submit')}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormDebt;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useQuery } from 'react-query';
import { CircularProgress } from '@mui/material';
import { getUserWealthDiagram } from '../../api'; // Import the API method
import { formatAmount } from '../../utils';

const WealthDiagram = ({ currency }) => {
  const { t } = useTranslation();

  // Fetch wealth data
  const { data: wealthData, isLoading, error } = useQuery('wealthData', getUserWealthDiagram, {
    onSuccess: data => {
      console.log('Wealth data fetched successfully:', data);
    }
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Ensure wealthData.data exists and is properly formatted
  const chartData = wealthData?.data
    ? Object.keys(wealthData.data).map(key => ({
        name: key,
        y: wealthData.data[key],
      }))
    : [];

  const xAxisCategories = wealthData?.data ? Object.keys(wealthData.data) : [];

  const options = {
    chart: {
      type: 'area',
      zoomType: 'x',
      width: null,  // Let Highcharts determine the width
      height: 450,
      backgroundColor: 'transparent',
      panning: true,
      panKey: 'shift',
      animation: true,
      options3d: { enabled: true },
    },
    title: null,
    xAxis: {
      categories: xAxisCategories, // Set x-axis categories directly
      tickmarkPlacement: 'on',
      title: null,
      labels: {
        rotation: -45,
        style: {
          fontSize: '13px',
          fontFamily: 'Arial, sans-serif',
        },
        formatter: function () {
          return this.value; // Display categories correctly
        },
      },
    },
    yAxis: {
      title: null,
      labels: {
        formatter: function () {
          return formatAmount(this.value, currency, true); // Format y-axis labels
        },
      },
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        name: t('wealth'),
        data: chartData,
        marker: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
          rotation: -45,
          color: '#333',
          align: 'center',
          formatter: function () {
            return formatAmount(this.y, currency, true);
          },
        },
        lineColor: Highcharts.getOptions().colors[2],
        color: Highcharts.getOptions().colors[1],
        fillOpacity: 0.5,
        threshold: null,
      },
    ],
    tooltip: {
      headerFormat: '',
      formatter: function () {
        return `<b>${this.point.name}</b>: ${formatAmount(this.point.y, currency, true)}`;
      },
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          chart: {
            scrollablePlotArea: {
              minWidth: 300, // Adjust for desired mobile display width
              scrollPositionX: 1,
            },
          },
        },
      }],
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default WealthDiagram;

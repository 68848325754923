import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import ProfileEdit from './FormProfile';
import { deleteUser, getConcierge } from '../../api';
import { useClient } from 'react-supabase';
import enFlag from '../../resources/en_flag.svg';
import frFlag from '../../resources/fr_flag.svg';
import { useNotification } from '../../context/NotificationContext';
import i18next from 'i18next';
import {
  Box,
  CircularProgress,
  Container,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Avatar,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function Profile() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const client = useClient();
  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { user, loading, saveUser, refreshUser } = useContext(UserContext);
  const [concierge, setConcierge] = useState(null);

  // State to control Modal visibility
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);

  useEffect(() => {
    const fetchConciergeDetails = async () => {
      if (user.role === 'PRO') {
        try {
          const response = await getConcierge(user.concierge_id);
          setConcierge(response.data);
        } catch (error) {
          console.error('Failed to fetch concierge details:', error);
        }
      }
    };

    const session = client.auth.session();
    if (session && session.user) {
      setUserEmail(session.user.email);
    }
    if (!user) {
      refreshUser(); // Attempt to refresh user data if not present
    } else {
      fetchConciergeDetails();
    }
  }, [user, refreshUser]);

  if (loading || !user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(i18next.language, options);
  };

  const handleCloseAccount = async () => {
    try {
      setIsLoading(true);
      await deleteUser();
      showNotification(t('alert_account_deleted'));
      setIsLoading(false);
      navigate('/login');
    } catch (error) {
      console.error('Failed to delete account', error);
      setIsLoading(false);
      showNotification('Failed to delete account');
    }
  };

  const handleClosureConfirmation = () => {
    const userConfirmation = prompt(t('confirm_account_closure'));
    if (userConfirmation === 'CONFIRM') {
      handleCloseAccount();
    } else {
      showNotification("Account closure cancelled.");
    }
  };

  return (
    <Container maxWidth="md">
      <Card sx={{ my: 3, p: 3 }}>
        <CardContent sx={{ textAlign: 'center' }}>
          <Avatar sx={{ width: 80, height: 80, mx: 'auto', mb: 2 }}>
            <AccountCircleIcon sx={{ width: 80, height: 80 }} />
          </Avatar>
          <Typography variant="h5" gutterBottom>{`${user.surname} ${user.name}`}</Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>{user.email || t('email_not_available')}</Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>{user.phone || t('no_tel')}</Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body2" color="textSecondary" gutterBottom>{`${t('country')}: ${user.country}`}</Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>{`${t('currency')}: ${user.currency}`}</Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {`${t('language')}: `}
            <img className="language-flag" src={user.language === 'en' ? enFlag : frFlag} alt="Language Flag" />
          </Typography>
          <Divider sx={{ my: 1 }} />

          <Typography variant="body2" color="textSecondary" gutterBottom>{t('account_type')}: {t(user.role)}</Typography>
          <Typography variant="body2" color="textSecondary">{t('subscription')}: {user.plan_expiry_date ? formatDate(user.plan_expiry_date) : t('free')}</Typography>
          {user.role === 'PRO' && concierge && (
            <>
              <Divider sx={{ my: 1 }} />
              <Typography variant="h6">{t('company')}</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>{t('name')}: {concierge.name}</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>{t('category')}: {concierge.category}</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>{t('address')}: {concierge.address}</Typography>
            </>
          )}
        </CardContent>
        <CardActions sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <Button
            startIcon={<EditIcon />}
            variant="contained"
            color="primary"
            onClick={toggleShow}
            sx={{ mb: 1, width: '100%' }}
          >
            {t('edit')}
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            variant="outlined"
            color="secondary"
            onClick={handleClosureConfirmation}
            sx={{ width: '100%' }}
          >
            {t('close_account')}
          </Button>
        </CardActions>
      </Card>
      <Dialog open={basicModal} onClose={toggleShow} fullWidth maxWidth="sm">
        <DialogTitle sx={{ backgroundColor: '#f5f5f5' }}>
          {user.name ? t('edit_user') : t('add_info')}
        </DialogTitle>
        <DialogContent>
          <ProfileEdit user={user} saveUser={saveUser} open={basicModal} onClose={toggleShow} readOnly={true} />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleShow} color="secondary">
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, MenuItem, CircularProgress } from '@mui/material';
import { configBrands, configModels } from '../../../../api';

const detailsAuto = forwardRef(({ data, user, t, onValidSubmit, cat }, ref) => {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [loadingModels, setLoadingModels] = useState(false);

  const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
    defaultValues: {
      brand: data?.brand || '',
      model: data?.model || '',
      release_year: data?.release_year || '',
    },
    mode: "onChange"
  });

  useEffect(() => {
    if (data) {
      for (const key in data) {
        setValue(key, data[key] ?? '');
      }
    }
  }, [data, setValue]);

  useImperativeHandle(ref, () => ({
    submitForm: () => handleSubmit(handleFormSubmit)(),
  }));

  useEffect(() => {
    // Fetch car brands
    configBrands()
      .then(response => {
        setBrands(response.data);
        const selectedBrand = getValues("brand");
        if (selectedBrand) {
          handleBrandChange({ target: { value: selectedBrand } }, true);
        }
      })
      .catch(error => console.error('Error fetching car brands:', error));
  }, [getValues]);

  const handleFormSubmit = (formData) => {
    const brandName = brands.find(brand => brand.id === formData.brand).name;
    onValidSubmit({
      ...formData,
      brand: brandName,
      "estimation": 0,
    });
    console.log("Form submitted", formData);
  };

  const handleBrandChange = async (event, initial = false) => {
    const brandId = event.target.value;
    setValue('brand', brandId);
    setModels([]);
    setLoadingModels(true);

    try {
      const response = await configModels(brandId);
      setModels(response.data);
      setLoadingModels(false);
    } catch (error) {
      console.error('Error fetching car models:', error);
      setLoadingModels(false);
    }

    if (initial) {
      setValue('model', data.model);
    }
  };

  const renderTextField = (name, label, type) => (
    <Controller
      name={name}
      control={control}
      rules={{
        required: "Year is required" ,
        ...(type === 'number' && { min: 0, valueAsNumber: true })
      }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          label={t(label)}
          type={type}
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : ''}
          margin="normal"
          fullWidth
        />
      )}
    />
  );

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Controller
        name="brand"
        control={control}
        rules={{ required: t('brand') }}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t('brand')}
            margin="normal"
            fullWidth
            helperText={errors.brand ? errors.brand.message : ''}
            onChange={(e) => {
              field.onChange(e);
              handleBrandChange(e);
            }}
          >
            {brands.map((brand) => (
              <MenuItem key={brand.id} value={brand.id}>
                {brand.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        name="model"
        control={control}
        rules={{ required: t('Model is required') }}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t('Model')}
            margin="normal"
            fullWidth
            error={!!errors.model}
            helperText={errors.model ? errors.model.message : ''}
            disabled={loadingModels}
          >
            {loadingModels ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              models.map((model, index) => (
                <MenuItem key={index} value={model}>
                  {model}
                </MenuItem>
              ))
            )}
          </TextField>
        )}
      />
      {renderTextField('release_year', 'release_year', 'number')}
    </Box>
  );
});

export default detailsAuto;

import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { currencySymbol } from '../../utils';
import { CASH_TRANSACTION } from '../../constants';

const FormTransaction = ({ user, apply, open, onClose }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    defaultValues: {
      date: new Date().toISOString().split('T')[0],
      type: CASH_TRANSACTION.DEPOSIT,
      amount: '',
      description: '',
    },
    mode: 'onChange',
  });

  // Custom validation for amount
  const validateAmount = (value) => {
    if (watch('type') === CASH_TRANSACTION.WITHDRAW && parseFloat(value) > user.cash_balance) {
      return t('amount_exceeds_balance');
    }
    return true;
  };

  const onSubmitHandler = (data) => {
    apply(data);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('add_transaction')}</DialogTitle>

      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('date')}
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.date}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('type')}
                    select
                    fullWidth
                    error={!!errors.type}
                  >
                    <MenuItem value={CASH_TRANSACTION.DEPOSIT}>{t('deposit')}</MenuItem>
                    <MenuItem value={CASH_TRANSACTION.WITHDRAW}>{t('withdrawal')}</MenuItem>
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="amount"
                control={control}
                rules={{
                  required: t('amount_required'),
                  validate: validateAmount,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('amount')}
                    type="number"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {currencySymbol(user.currency)}
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.amount}
                    helperText={errors.amount ? errors.amount.message : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                rules={{ required: t('description_required') }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('description')}
                    fullWidth
                    multiline
                    rows={2}
                    error={!!errors.description}
                    helperText={errors.description ? errors.description.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t('cancel')}
          </Button>
          <Button color="primary" type="submit" disabled={!isValid}>
            {t('submit')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormTransaction;

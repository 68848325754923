import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils';
import { useNavigate } from 'react-router-dom';
import {
  Container, Grid, Box, Tabs, Tab, Tooltip, CircularProgress, Avatar,
  Card, CardContent, Typography, useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from '@mui/icons-material/Home';
import MainCard from '../../utils/MainCard';
import './Dashboard.css';

// Mock API function
const getProfessionalData = async (dataType) => {
  const mockData = {
    total_revenue: 75000,
    total_expense: 30000,
    occupancy_rate: 85,
    active_assets: 15,
    occupancy: [
      { month: 'Jan-2023', rate: 80 },
      { month: 'Feb-2023', rate: 82 },
      { month: 'Mar-2023', rate: 83 },
      { month: 'Apr-2023', rate: 85 },
      { month: 'May-2023', rate: 86 },
      { month: 'Jun-2023', rate: 88 },
      { month: 'Jul-2023', rate: 85 },
    ],
    expenses: [
      { category: 'Maintenance', amount: 8000 },
      { category: 'Utilities', amount: 5000 },
      { category: 'Salaries', amount: 10000 },
      { category: 'Miscellaneous', amount: 7000 },
    ],
  };

  return { data: mockData[dataType] };
};

// Mock diagrams components
const PerformanceOccupancy = ({ occupancyData }) => (
  <div>
    <h2>Occupancy Performance</h2>
    <pre>{JSON.stringify(occupancyData, null, 2)}</pre>
  </div>
);

const PerformanceExpenses = ({ expensesData }) => (
  <div>
    <h2>Expenses Breakdown</h2>
    <pre>{JSON.stringify(expensesData, null, 2)}</pre>
  </div>
);

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '12px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6],
  },
}));

const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const CardValue = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '50px',
  minHeight: '50px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '50%',
  padding: theme.spacing(1),
}));

const DashboardPro = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [totalRevenue, setTotalRevenue] = useState(null);
  const [totalExpense, setTotalExpense] = useState(null);
  const [occupancyRate, setOccupancyRate] = useState(null);
  const [activeAssets, setActiveAssets] = useState(null);
  const [occupancyData, setOccupancyData] = useState(null);
  const [expensesData, setExpensesData] = useState(null);
  const [tabValue, setTabValue] = useState('performance');
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleClick = (tooltip) => {
    setTooltipContent(tooltip);
    setTooltipOpen(true);
  };

  const handleClose = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    if (!user || !user.id) {
      setLoading(false);
      return;
    }

    const fetchProfessionalData = async () => {
      try {
        const userId = user.id;
        const revenueResult = await getProfessionalData('total_revenue');
        const expenseResult = await getProfessionalData('total_expense');
        const occupancyRateResult = await getProfessionalData('occupancy_rate');
        const activeAssetsResult = await getProfessionalData('active_assets');
        const occupancyDataResult = await getProfessionalData('occupancy');
        const expensesDataResult = await getProfessionalData('expenses');

        if (revenueResult && revenueResult.data !== undefined) {
          setTotalRevenue(revenueResult.data);
        }

        if (expenseResult && expenseResult.data !== undefined) {
          setTotalExpense(expenseResult.data);
        }

        if (occupancyRateResult && occupancyRateResult.data !== undefined) {
          setOccupancyRate(occupancyRateResult.data);
        }

        if (activeAssetsResult && activeAssetsResult.data !== undefined) {
          setActiveAssets(activeAssetsResult.data);
        }

        if (occupancyDataResult && occupancyDataResult.data) {
          setOccupancyData(occupancyDataResult.data);
        }

        if (expensesDataResult && expensesDataResult.data) {
          setExpensesData(expensesDataResult.data);
        }
      } catch (error) {
        console.error('Error fetching professional data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfessionalData();
  }, [user]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const cardsData = [
    { icon: <AttachMoneyIcon color="info" />, value: formatAmount(totalRevenue, user.currency), label: t('total_revenue'), tooltip: t('tooltip_total_revenue') },
    { icon: <BarChartIcon color="error" />, value: formatAmount(totalExpense, user.currency), label: t('total_expense'), tooltip: t('tooltip_total_expense') },
    { icon: <TrendingUpIcon color="success" />, value: `${occupancyRate}%`, label: t('occupancy_rate'), tooltip: t('tooltip_occupancy_rate') },
    { icon: <HomeIcon color="primary" />, value: activeAssets, label: t('active_assets'), tooltip: t('tooltip_active_assets') },
  ];

  return (
    <Container>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="center" my={2}>
            <MainCard
              title={t('profit')}
              value={totalRevenue}
              currency={user.currency}
              tooltip={t('tooltip_total_revenue')}
              size='h4'
            />
          </Box>

          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label={t('performance')} value="performance" />
            <Tab label={t('occupancy')} value="occupancy" />
            <Tab label={t('expenses')} value="expenses" />
          </Tabs>

          {tabValue === 'performance' && <PerformanceOccupancy occupancyData={occupancyData} />}
          {tabValue === 'occupancy' && <PerformanceOccupancy occupancyData={occupancyData} />}
          {tabValue === 'expenses' && <PerformanceExpenses expensesData={expensesData} />}
          <Grid container spacing={3} my={3}>
              {cardsData.map((card, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Tooltip
                    title={card.tooltip}
                    open={tooltipOpen && tooltipContent === card.tooltip}
                    onClose={handleClose}
                    disableHoverListener
                    disableFocusListener
                    arrow
                  >
                    <StyledCard raised onClick={() => handleClick(card.tooltip)}>
                      <CardContent>
                        <CardHeader>
                          <IconWrapper>
                            <Avatar sx={{ bgcolor: 'transparent' }}>
                              {card.icon}
                            </Avatar>
                          </IconWrapper>
                          <div>
                            <Typography variant="subtitle2" gutterBottom>
                              {card.label}
                            </Typography>
                            <CardValue>
                              {card.value}
                            </CardValue>
                          </div>
                        </CardHeader>
                      </CardContent>
                    </StyledCard>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
        </>
      )}
    </Container>
  );
};

export default DashboardPro

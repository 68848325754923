import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils';


const AssetPerfDiagram = ({ performance, currency, assetName }) => {


  const prepareChartData = (dataDict) => {
    return Object.keys(dataDict).map((date) => ({
      name: date,
      y: dataDict[date],
    }));
  };

  const totalReturnData = prepareChartData(performance);

  const options = {
    chart: {
      type: 'line',
      height: 450,
      backgroundColor: 'transparent',
    },
    title: null,
    xAxis: {
      type: 'category',
      labels: {
        rotation: -45,
        style: {
          fontSize: '13px',
          fontFamily: 'Arial, sans-serif',
        },
      }
    },
    yAxis: {
      title: null,
      labels: {
        formatter: function () {
          return formatAmount(this.value, currency, true);
        },
      },
    },
    series: [

      {
        name: assetName,
        data: totalReturnData,
        marker: {
          enabled: true
        },
        lineWidth: 2, // Ensure the line is visible
      }
    ],
    tooltip: {
      shared: true,
      headerFormat: '',
      formatter: function () {
        let s = ``;
        this.points.forEach(function(point) {
          s += `<b>${point.series.name}</b>: ${formatAmount(point.y, currency, true)}<br/>`;
        });
        return s;
      },
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          chart: {
            scrollablePlotArea: {
              minWidth: 300,
              scrollPositionX: 1
            }
          },
          legend: {
            enabled: false,}
        },
      }],
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <p></p>
    </div>
  );
};

export default AssetPerfDiagram;

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useClient } from 'react-supabase'
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Button,
  Typography
} from '@mui/material';
import { useNotification } from '../../context/NotificationContext';

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showNotification } = useNotification();
  const client = useClient()
  // Validation Schema
  const validationSchema = yup.object().shape({
    password: yup.string().min(6, t('passwordMin')).required(t('passwordRequired')),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], t('passwordsMustMatch')).required(t('confirmPasswordRequired'))
  });


  // Setup React Hook Form
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      // Call API to update password
      const { error } = await client.auth.update({
        password: data.password
      });
      if (error) {
        setMessage(`Error: ${error.message}`);
      } else {
        setMessage('Password updated successfully!');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      setMessage(`Error: ${error.message}`);
      showNotification(t('passwordUpdateFail'));
    } finally {
      setIsSubmitting(false);
      navigate('/login'); // Redirect to login or other page as needed
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Card variant="outlined" sx={{ my: 4, p: 3 }}>
          <CardHeader
            title={<Typography variant="h5" align="center">{t('updatePassword')}</Typography>}
            sx={{ pb: 0 }}
          />
          <CardContent>
          {message && <p>{message}</p>}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('password')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type='password'
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('confirmPassword')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type='password'
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                  />
                )}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled={isSubmitting}
                type="submit"
              >
                {t('update')}
              </Button>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default UpdatePassword;

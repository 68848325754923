import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, InputAdornment } from '@mui/material';

const DetailsImmo = forwardRef(({ memo, assetIn,  user, t, onValidSubmit }, ref) => {

  const { control, handleSubmit, setValue, trigger } = useForm({
    defaultValues: {
      category: assetIn.category,
      rooms: '', // Set to empty string if null initially
      construction_year: '', // Set to empty string if null initially
      surface: '', // Set to empty string if null initially
    },
    mode: "onChange"
  });

  useImperativeHandle(ref, () => ({
    submitForm: () => handleSubmit(handleFormSubmit)(),
  }));

  useEffect(() => {
    for (const key in memo) {
      setValue(key, memo[key] ?? '');
    }
  }, [memo, setValue]);


  const handleFormSubmit = (data) => {
    // Create a new object for cleaned data
    let cleanedData = {};

    // Iterate over the data object and remove empty fields
    Object.entries(data).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        cleanedData[key] = value;
      }
    });

      // Submit cleaned data
    onValidSubmit(cleanedData);
    console.log("Form DetailsImmo submitted", cleanedData);
  };

  const handleSizeChange = (e) => {
    const value = e.target.value;
    // Check if the value is a non-negative number
    if (!isNaN(value) && value >= 0) {
      // Update the form state
      setValue('surface', value, { shouldValidate: true });
    }
    else trigger('surface');
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Controller
          name="surface"
          control={control}
          rules={{ required: t('required'), validate: value => value > 0 }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={t('surface')}
              type="number"
              onChange={handleSizeChange}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">m²</InputAdornment>,
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />)}
        />
        { assetIn?.category !== 'LAND' &&
          <Box display="flex" justifyContent="space-between">
            <Controller
              name="rooms"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t('number_of_rooms')}
                  type="number"
                  placeholder={t('optional')}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  style={{ marginRight: '4px' }}
                />
              )}
            />

            <Controller
              name="construction_year"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number" // Changed type to 'number' for year input
                  margin="normal"
                  placeholder={t('optional')}
                  label={t('construction_year')}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Box>

        }
    </Box>

  );
});

export default DetailsImmo;


/*


<Box>
<Card>
{cat=='LAND' && sectorData && communeData &&
      <CardContent>

        <Typography variant="subtitle1">{t('sector_prices')}</Typography>
        <br></br>
        <div className={classes.barContainer}>
          <div className={classes.gradientBar}></div>

          <span className={classes.label} style={{ left: '0%', transform: 'translateX(0%)' }}>Min commune</span>
          <span className={classes.calculatedValue} style={{ left: '0%', transform: 'translateX(0%)' }}>
            {communeData ? calculateDisplayedValue(communeData.Min, user.currency) : 'N/A'}
          </span>


          <span className={classes.label} style={{ left: '50%', transform: 'translateX(-50%)' }}>{t('average')} commune</span>
          <span className={classes.calculatedValue} style={{ left: '50%', transform: 'translateX(-50%)' }}>
            {communeData ? calculateDisplayedValue(communeData.Average, user.currency) : 'N/A'}
          </span>



          <span className={classes.label} style={{ left: '100%', transform: 'translateX(-100%)' }}>Max commune</span>
          <span className={classes.calculatedValue} style={{ left: '100%', transform: 'translateX(-100%)' }}>
            {communeData ? calculateDisplayedValue(communeData.Max, user.currency) : 'N/A'}
          </span>
          {sectorData && communeData &&
            <span className={classes.cursor} style={{ left: `calc(${sectorData.Average * 100 / communeData.Max}% - 5px)` }}></span>}
          {sectorData && communeData && <span className={classes.averageValueLabel} style={{ left: `calc(${sectorData.Average * 100 / communeData.Max}% - 5px)` }}>
            {calculateDisplayedValue(sectorData.Average, user.currency)}
          </span>}


        </div>

        <p></p><br></br>
        <div className={classes.sourceText}>
          Source: DGI/Direction du Cadastre.
        </div>

      </CardContent>
      }
    </Card>

    </Box>
    */

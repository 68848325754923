import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { formatAmount } from '../utils';
import { useNotification } from '../context/NotificationContext';
import Slider from '@mui/material/Slider';

const EstimationCard = ({ estimation, location, details, currency, t, handleDeleteEstimation = null }) => {

  const showNotification = useNotification();

  const PriceComparisonCard = ({ minPrice, maxPrice, myPrice, currency, t, city }) => {
    // Ensure minPrice is not greater than maxPrice
    const safeMinPrice = Math.min(minPrice, maxPrice);
    const safeMaxPrice = Math.max(minPrice, maxPrice);

    // Compute the price position
    let pricePosition;
    if (myPrice <= safeMinPrice) {
      pricePosition = 0;
    } else if (myPrice >= safeMaxPrice) {
      pricePosition = 100;
    } else {
      const range = safeMaxPrice - safeMinPrice;
      const differenceFromMin = myPrice - safeMinPrice;
      pricePosition = (differenceFromMin / range) * 100;
    }
    const marks = [
      {
        value: 0,
        label: ``,
      },
      // Assuming you want to show minPrice and maxPrice as well, not just the adjusted values
      {
        value: 20, // This value should be adjusted based on your slider's logic
        label: `${formatAmount(minPrice, currency, true)}/m²`,
      },
      {
        value: 80, // This value should be adjusted based on your slider's logic
        label: `${formatAmount(maxPrice, currency, true)}/m²`,
      },
      {
        value: 100,
        label: ``,
      },
    ];

    return (
      <Card sx={{ boxShadow: 3, margin: 2, borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h7" textAlign="center" gutterBottom>
            {t('prices_in_city')} {city}
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Slider
                valueLabelDisplay="auto"
                marks={marks}
                disabled
                defaultValue={pricePosition}
                sx={{
                  // Target the track and thumb for custom styling
                  '& .MuiSlider-track': {
                    backgroundImage: 'linear-gradient(to right, green , red)',
                  },
                  '& .MuiSlider-thumb': {
                    backgroundColor: '#fff', // Optional: Adjust thumb color
                  },
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };


  const characteristicCircle = (label) => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 60, // Width of the circle
      height: 50, // Height of the circle
      borderRadius: '50%', // Make it circular
      backgroundColor: 'transparent', // Background color
      border: '1px solid #e0e0e0',
      color: 'black', // Text color
      margin: '5px', // Space between circles
    }}>
      <Typography variant="body2" align="center">
        {`${label}`}
      </Typography>
    </Box>
  );

  const handleClosureConfirmation = (estimationId) => {
    // window.confirm() returns true if the user clicked "OK" and false if the user clicked "Cancel"
    const userConfirmation = window.confirm(t('confirm_estimation_deletion'));

    if (userConfirmation) {
      handleDeleteEstimation(estimationId);
    } else {
      showNotification(t('deletion_cancelled'));
    }
  };

  return (
    <Card sx={{ boxShadow: 3, margin: 2, borderRadius: 2 }}>
      <CardContent >
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          {t('price_estimation')}
        </Typography>

        <Typography color="text.secondary" sx={{ textAlign: 'center' }}>
          {location?.address} {location?.quartier}
        </Typography>
        <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
          {details?.surface && characteristicCircle(`${details.surface} m2`)}
          {details?.rooms && characteristicCircle(`${details.rooms} ${t('rooms')}`)}
          {location?.city && characteristicCircle(`${location?.city}`)}
          {location?.region && characteristicCircle(`${location?.region}`)}
        </Grid>
        <Box textAlign="center">
          <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
            {formatAmount(estimation?.average, currency)}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {t('soit')} {formatAmount(estimation?.average / details?.surface, currency)}/m²
          </Typography>
          {/* Additional Disclaimer and CTA */}
          <PriceComparisonCard minPrice={estimation?.min_sector / details?.surface} maxPrice={estimation?.max_sector / details?.surface} currency={currency} t={t} city={location?.city} myPrice={estimation?.average / details?.surface} />
        </Box>

        <Typography variant="subtitle2" sx={{ mt: 2, textAlign: 'center' }}>
          {t('accuracy_disclaimer')}
        </Typography>
      </CardContent>


      <CardActions sx={{ justifyContent: 'space-between' }}>
        {handleDeleteEstimation && estimation.visible &&
          <Button size="small" color="error" onClick={() => handleClosureConfirmation(estimation.id)}>{t('delete')}</Button>
        }
        <Button size="small">{t('contact')}</Button>

      </CardActions>
    </Card>
  );
};

export default EstimationCard;

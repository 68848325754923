import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext'; // adjust path as needed

function Logout() {
  const navigate = useNavigate();
  const { saveUser } = useContext(UserContext);
  console.log("I logout myself")
  useEffect(() => {
    // assuming setUser sets the user state to null or another initial state
    saveUser(null);
    localStorage.removeItem('user'); // if you store token or user info in local storage

    // Redirecting user to home or login page after logout
    navigate('/');
  }, [navigate, saveUser]);

  return null;
}

export default Logout;

import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ClickableTooltip from './ClickableTooltip';
import { formatAmount, formatChangeText } from '../utils';
import { useTranslation } from "react-i18next";

const MainCard = ({
  title,
  value,
  m_change, // Month change
  y_change, // Year change
  currency,
  size, // size of the amount text
  tooltip, // tooltip text
  flagColor,
  simplify = false
}) => {

  const { t } = useTranslation();
  // Calculate the percentage changes
  const mChangePercent = value !== m_change ? (m_change / Math.abs(value )) * 100 : 100;
  const yChangePercent = value !== y_change ? (y_change / Math.abs(value )) * 100 : 100;
  const color = flagColor == null ? null : value >= 0 ? 'green' : 'red';
  return (
    <Card
      sx={{
        mb: 1, backgroundColor: 'rgba(0, 0, 0, 0.03)', borderRadius: 1, border: '1px solid #e0e0e0', p: -1
      }}
    >
      <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'center'}}>
        <ClickableTooltip title={tooltip}>
          <Typography color="textSecondary" gutterBottom>
            {title}
          </Typography>
        </ClickableTooltip>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb:1 }}>
        <Typography variant={size} component={size} style={{ fontWeight: 'bold', color: color }}> {/* Changed the variant for visibility */}
          {formatAmount(value, currency, simplify, true)}
        </Typography>
        </Box>

        {y_change != 0 && formatChangeText(m_change, mChangePercent, t("MTD"), currency)}
        {y_change != 0 && <br/>}
        {y_change != 0 && formatChangeText(y_change, yChangePercent, t("YTD"), currency)}
      </CardContent>
    </Card>
  );
};

export default MainCard;

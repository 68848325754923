import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { configAssetCategories } from '../../../../api';
import ExploitationSelector from '../components/ExploitationSelector';

const GeneralForm = forwardRef(({ assetIn, user, t, onValidSubmit }, ref) => {
  const [assetCategories, setAssetCategories] = useState({});
  const type = assetIn.type;

  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      name: assetIn?.name || '',
      description: assetIn?.description || '',
      category: assetIn?.category || '',
      exploitation: assetIn?.exploitation || '',
      purchase_date: assetIn?.purchase_date || new Date().toISOString().split('T')[0],
    },
    mode: "onChange"
  });

  useImperativeHandle(ref, () => ({
    submitForm: () => handleSubmit(handleFormSubmit)(),
  }));

  // Fetch asset categories on component mount
  useEffect(() => {
    configAssetCategories()
      .then(response => {
        setAssetCategories(response.data);
      })
      .catch(console.error);
  }, []);

  // Dynamically set the category's default value when type changes
  useEffect(() => {
    if (!assetIn.category && assetCategories && assetCategories[type] && assetCategories[type].length > 0) {
      setValue("category", assetCategories[type][0]);
    }
    else if (assetIn.category) setValue("category", assetIn.category);
  }, [assetIn.category, type, assetCategories, setValue]);

  useEffect(() => {
    if (assetIn) {
      console.log('Received assetIn:', assetIn);
      for (const key in assetIn) {
        setValue(key, assetIn[key]);
      }
    }
  }, [assetIn, setValue]);


  const handleFormSubmit = (data) => {
    onValidSubmit(data);// Pass the form data to the parent
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}
      sx={{
        '& .MuiTextField-root': {
          mb: 2,
          width: '100%'
        },
        '& .MuiFormControl-root': {
          mb: 2,
          width: '100%'
        }
      }}>
      {/* Form Fields */}
      <Controller
        name="name"
        control={control}
        rules={{ required: t('required') }}
        render={({ field, fieldState }) => <TextField {...field} label={t('name')} error={!!fieldState.error}
        helperText={fieldState.error ? fieldState.error.message : ''} />}
      />

      <Controller
        name="description"
        control={control}
        render={({ field }) => <TextField {...field} label={t('description')} helperText={t('optional')} />}
      />

      {/* Asset Category Field */}
      <FormControl fullWidth error={!!errors.category} >
        <InputLabel id="category-label">{t('category')}</InputLabel>
        <Controller
          name="category"
          control={control}
          rules={{ required: t('category_required') }}
          render={({ field }) => (
            <Select {...field} labelId="category-label" label={t('category')}>
              {assetCategories && assetCategories[type] && assetCategories[type].map(category => (
                <MenuItem key={category} value={category}>{t(category)}</MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{errors.category?.message}</FormHelperText>
      </FormControl>


      <ExploitationSelector
        control={control}
        errors={errors}
        selectedAssetType={type}
        t={t}
        setValue={setValue}
        exploi_default={assetIn?.exploitation}
      // Add more bottom margin to the ExploitationSelector
      />

      {/* Purchase Date Field */}
      <Controller
        name="purchase_date"
        control={control}
        rules={{ required: t('purchase_date_required') }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            type="date"
            label={type === "FINANCIAL_PRODUCT" ? t('opening_date') : t('purchase_date')}
            InputLabelProps={{ shrink: true }}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </Box>
  );
});

export default GeneralForm;

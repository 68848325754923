import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FormEditAsset = ({ apply, toggleShow, asset }) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: asset?.name,
      description: asset?.description || '',
      notes: asset?.notes || '',
    },
  });

  const onSubmitHandler = (data) => {
    if (data && data.name) {
      apply(data);
      toggleShow();
    }
  };

  return (
    <Dialog open={true} onClose={toggleShow} fullWidth maxWidth="sm">
      <DialogTitle>{t('edit_asset')}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <DialogContent>
          {/* Name Field */}
          <Controller
            name="name"
            control={control}
            rules={{ required: t('name_required') }}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('name')}
                fullWidth
                margin="dense"
                required
              />
            )}
          />

          {/* Description Field */}
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('description')}
                fullWidth
                multiline
                rows={3}
                margin="dense"
              />
            )}
          />

          {/* Notes Field */}
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('notes')}
                fullWidth
                multiline
                rows={3}
                margin="dense"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleShow} color="secondary">
            {t('cancel')}
          </Button>
          <Button color="primary" type="submit">
            {t('submit')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormEditAsset;

import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { configAssetExploitations } from '../../../../api';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';

const ExploitationSelector = ({ exploi_default, control, errors, selectedAssetType, t, setValue }) => {
  const [assetExploitations, setAssetExploitations] = useState({});

  useEffect(() => {
    configAssetExploitations()
      .then((response) => setAssetExploitations(response.data))
      .catch(console.error);
  }, []);

  // Dynamically set the category's default value
  useEffect(() => {
    if (!exploi_default && assetExploitations[selectedAssetType]?.length > 0) {
      setValue("exploitation", assetExploitations[selectedAssetType][0]);
    } else if (exploi_default) {
      setValue("exploitation", exploi_default);
    }
  }, [assetExploitations, selectedAssetType, setValue]);

  return (
    <>

      {assetExploitations[selectedAssetType] && assetExploitations[selectedAssetType].length > 0 && (
        <>
          <FormControl fullWidth error={!!errors.exploitation}>
            <InputLabel id="exploitation-label">{t('exploitation')}</InputLabel>
            <Controller
              name="exploitation"
              control={control}
              rules={{ required: `${t('required')}` }}
              render={({ field }) => (
                <Select {...field} labelId="exploitation-label" label={t('exploitation')}>
                  {assetExploitations && assetExploitations[selectedAssetType] && assetExploitations[selectedAssetType].map(exploitation => (
                    <MenuItem key={exploitation} value={exploitation}>{t(exploitation)}</MenuItem>
                  ))}
                </Select>

              )}
            />
            <FormHelperText>{errors.exploitation?.message}</FormHelperText>
          </FormControl>
        </>

      )}

    </>
  );
};
export default ExploitationSelector;

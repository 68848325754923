import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

import MyAssets from './MyAssets';
import AssetsPro from './AssetsPro';


const Assets = () => {
  const { user } = useContext(UserContext);
  return (
    ['PRO', 'ADMIN'].includes(user.role) ? <AssetsPro user={user} /> : <MyAssets user={user} />
  );
};

export default Assets;

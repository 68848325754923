import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Box, CircularProgress, Container, Fab, Grid, Button, Fade, Tab, Tabs, Typography, Modal
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import MainCard from '../../utils/MainCard';
import { getAssets, deleteAsset, createAsset, historyUser } from '../../api';
import AssetCreationWizard from './create/AssetCreationWizard';
import { useTranslation } from 'react-i18next';
import AssetCard from '../../utils/AssetCard'; // Assuming AssetCard is moved to its own file
import SwipeableViews from 'react-swipeable-views';
import { formatAmount } from '../../utils';
import ConfirmDialog from '../../utils/ConfirmDialog';
import LoadingModal from '../../utils/LoadingModal';
import { useNotification } from '../../context/NotificationContext'; // Import the notification hook


const MyAssets =  ({user}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [typedAssets, setTypedAssets] = useState({});
  const [totalValue, setTotalValue] = useState(0);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [assetIdToDelete, setAssetIdToDelete] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const { isLoading, refetch } = useQuery(
    ['assets', user?.id],
    () => getAssets(false),
    {
      enabled: !!user?.id,
      onSuccess: (response) => {
        // Destructure the 'data' array from the response object
        const { data } = response;

        if (Array.isArray(data)) {
          // Now we are working with the actual data array
          const grouped = data.reduce((acc, asset) => {
            const { type } = asset;
            acc[type] = acc[type] || { items: [], totalValue: 0 };
            acc[type].items.push(asset);
            acc[type].totalValue += asset.value;
            return acc;
          }, {});

          setTypedAssets(grouped);
          setTotalValue(data.reduce((sum, asset) => sum + asset.value, 0));
        } else {
          console.error('Expected an array, but received:', data);
          showNotification(t('failed_retrieving_assets_data') + ': ' + data, 'error');
        }
      },
    }
  );
  const { data: history, isLoading: historyLoading } = useQuery(
    ['history', user.id],
    async () => {
      const response = await historyUser();
      return response.data; // Directly return the data object from the response
    },
    {
      enabled: user.role === 'INDIVIDUAL', // This should be part of the options object
    }
  );

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const handleDeleteAsset = (assetId) => {
    setAssetIdToDelete(assetId);
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!assetIdToDelete) return;
    setIsCreating(true);
    try {
      await deleteAsset(assetIdToDelete);
      refetch(); // Refetch transactions after the asset is deleted
      showNotification(t('asset_deleted_successfully'), 'success');
    }
    catch (error) {
      showNotification(t('failed_retrieving_assets_data') + ': ' + error.response?.data?.detail, 'error');
    }
    finally {
      setIsCreating(false); // Set loading to false
      setIsDialogOpen(false);
    }
  };

  const handleOpenWizard = () => {
    setIsWizardOpen(true);
  };

  const handleCloseWizard = () => {
    console.log('Closing wizard unexpectedly');
    setIsWizardOpen(false);
  };

  // Call by the modal form to add a new asset
  const handleWizardSubmitAsset = async (formData, flagNewDeposit, details, forecast, location = null) => {
    try {
      setIsCreating(true);
      // Check if 'details' is an empty object and set it to null if it is
      const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
      details = isEmptyObject(details) ? null : details;
      location = isEmptyObject(location) ? null : location;
      forecast = isEmptyObject(forecast) ? { "cashflow": 0, "appreciation": 10, "period": 10 } : forecast;
      console.log('Creating asset with data:', formData, details, forecast, location, flagNewDeposit);
      await createAsset(formData, details, forecast, location, flagNewDeposit);
      refetch();  // Refetch transactions after a new one is added
      showNotification(t('asset_created_successfully'), 'success');

    } catch (error) {
      console.error('An error occurred while creating Asset:', error);
      showNotification(t('failed_retrieving_assets_data') + ': ' + error.response?.data?.detail, 'error');
    } finally {
      setIsCreating(false);  // Set isCreating to false when the process completes
      setIsWizardOpen(false); // Close the wizard upon completion or failure
    }
  };

  return (
    <Container maxWidth="md">
      {(historyLoading || isLoading || isCreating) ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {Object.keys(typedAssets).length === 0 ? (
            <Box textAlign="center" mt={5}>
              <Typography variant="h6" gutterBottom>
                {t('welcome_to_assets')}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {t('no_assets_prompt')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenWizard}
                sx={{ mt: 3 }}
              >
                {t('add_your_first_asset')}
              </Button>
            </Box>
          ) : (
            <>
              <Box display="flex" justifyContent="center" my={0}>
                <MainCard
                  title={t('assets_total_value')}
                  value={totalValue}
                  currency={user.currency}
                  m_change={history ? history.value_mtd : 0}
                  y_change={history ? history.value_ytd : 0}
                  size='h4'
                />
              </Box>

              <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" style={{ marginBottom: '10px' }} >
                {Object.keys(typedAssets).map((type, index) => (
                  <Tab
                    label={
                      <>
                        {t(type)}
                        <Typography variant="subtitle1" component="span" style={{ marginLeft: '8px' }}>
                          {formatAmount(typedAssets[type].totalValue, user.currency)}
                        </Typography>
                      </>
                    }
                    key={type}
                  />
                ))}
              </Tabs>

              <SwipeableViews axis="x" index={activeTab} onChangeIndex={setActiveTab}>
                {Object.entries(typedAssets).map(([type, assets], index) => (
                  <TabPanel value={activeTab} index={index} key={type}>
                    <Grid container spacing={2}>
                      {assets.items.map((asset) => (
                        <AssetCard key={asset.id} asset={asset} onDelete={handleDeleteAsset} onOpen={() => navigate(`/assets/${asset.id}`)} currency={user.currency} />
                      ))}
                    </Grid>
                  </TabPanel>
                ))}
              </SwipeableViews>

              <div>
                <Fab
                  color="primary"
                  aria-label={t('add_asset')}
                  onClick={handleOpenWizard}
                  sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    width: 64,
                    height: 64,
                    fontSize: 32,
                  }}
                >
                  <AddIcon />
                </Fab>
                {/* Asset Creation Wizard */}
                {isWizardOpen && <AssetCreationWizard
                  applyAsset={handleWizardSubmitAsset}
                  onCancel={handleCloseWizard}
                  t={t}
                  user={user}
                />}
                <ConfirmDialog
                  open={isDialogOpen}
                  onClose={() => setIsDialogOpen(false)}
                  onConfirm={handleConfirmDelete}
                  message={t('confirm_asset_deletion')}
                />
              </div>
            </>
          )}

        </>
      )}
      {/* Modal for the Asset Creation Wizard */}
      <LoadingModal open={isCreating} />
      <Modal
        open={isWizardOpen}
        onClose={handleCloseWizard}
        closeAfterTransition
        backdropprops={{
          timeout: 1500,
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={isWizardOpen}>
          <Box sx={{
            background: 'linear-gradient(rgb(255, 255, 255), rgba(162, 225, 238, 0.988))',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxWidth: '500px',
            maxHeight: '90vh',
            overflow: 'auto'
          }}>
            <AssetCreationWizard
              applyAsset={handleWizardSubmitAsset}
              onCancel={handleCloseWizard}
              t={t}
              user={user}
            />
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default MyAssets;

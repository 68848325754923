import React, { useState, forwardRef, useEffect } from 'react';
import { Box, TextField, Button, Grid, InputAdornment, Typography } from '@mui/material';
import MetricCard from '../../../../utils/MetricCard';
import { currencySymbol } from '../../../../utils';
import { statsForecast } from '../../../../api';

const ForecastPlan = forwardRef(({ memo, assetIn, user, t, onForecastUpdate }, ref) => {
    const [annualIncome, setAnnualIncome] = useState(0);
    const [annualExpense, setAnnualExpense] = useState(0);
    const [appreciationRate, setAppreciationRate] = useState('');
    const [exploitationPeriod, setExploitationPeriod] = useState(1); // Default to 1 year

    const [forecastData, setForecastData] = useState({});

    // Handle numeric input changes and format them
    const handleNumericInputChange = (setter, value) => {
        setter(value); // Directly setting the value as we're taking care of formatting in the render
    };

    useEffect(() => {
        const init = async () => {
            try {
                await calculateMetrics();
            } catch (error) {
                console.error("Error in initialization:", error);
            }
        };
        init();
    }, []); // Empty dependency array means this runs once on mount

    const calculateMetrics = async () => {
        try {
            // Ensure fees are parsed as a float, defaulting to 0 if parsing fails or the input is empty
            const fees = parseFloat(assetIn.acquisition_cost) || 0;
            const price = parseFloat(assetIn.purchase_price) || 0;

            const data = {
                income: parseFloat(annualIncome) || 0,
                expense: parseFloat(annualExpense) || 0,
                cashflow: (parseFloat(annualIncome) || 0) - (parseFloat(annualExpense) || 0),
                appreciation: parseFloat(appreciationRate) || 0,
                period: parseInt(exploitationPeriod, 10) || 0,
                start: assetIn.purchase_date,
                price: price,
                fees: fees, // Use the parsed fees here
            };
            const response = await statsForecast(data);
            onForecastUpdate(data); // Assuming you handle the data update elsewhere
            setForecastData(response.data);
        } catch (error) {
            console.error('Error calculating forecast:', error);
        }
    };

    return (
        <Box sx={{ padding: 1, mt: -2 }}>
            <Typography sx={{ textAlign: 'center', mb: 1 }} >
                {t('this_step_is_optional')}
            </Typography>
            <Typography sx={{ mb: 1.5, color: 'gray', fontStyle: 'italic' }}>
                {t('forecast_explanation')}
            </Typography>
            <Grid  container spacing={1}>
                {/* Annual Income */}
                <Grid item xs={6} sm={6}>
                    <TextField
                        label={t('annual_income')}
                        value={annualIncome}
                        onChange={(e) => handleNumericInputChange(setAnnualIncome, e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
                        }}
                        type="text"
                        fullWidth
                    />
                </Grid>
                {/* Annual Expense */}
                <Grid item xs={6} sm={6}>
                    <TextField
                        label={t('annual_expense')}
                        value={annualExpense}
                        onChange={(e) => handleNumericInputChange(setAnnualExpense, e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
                        }}
                        type="text"
                        fullWidth
                    />
                </Grid>
                {/* Appreciation Rate */}
                <Grid item xs={6} sm={6}>
                    <TextField
                        label={t('appreciation')}
                        value={appreciationRate}
                        onChange={(e) => handleNumericInputChange(setAppreciationRate, e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        type="text"
                        fullWidth
                    />
                </Grid>
                {/* Exploitation Period */}
                <Grid item xs={6} sm={6}>
                    <TextField
                        label={t('exploitationPeriod')}
                        value={exploitationPeriod}
                        onChange={(e) => handleNumericInputChange(setExploitationPeriod, e.target.value.replace(/[^0-9]/g, ''))}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t('years')}</InputAdornment>,
                        }}
                        type="text"
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Button
                onClick={calculateMetrics}
                sx={{
                    mt: 2,
                    backgroundColor: 'white',
                    color: 'blue',
                    ':hover': {
                        backgroundColor: 'lightgray',
                    },
                    border: '1px solid black'
                }}>
                {t('calculate')}
            </Button>

            {/* Forecast metrics display */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, justifyContent: 'space-between', overflowX: 'auto' }}>
                <MetricCard t={t} title='irr' value={forecastData?.irr} size="h7" />
                <MetricCard t={t} title='roi/an' value={forecastData?.roi} size="h7" />
                <MetricCard t={t} title='Total ROI' value={forecastData?.cumulative_roi} size="h7" />
                <MetricCard t={t} title='cashflow' value={forecastData?.cumulative_cashflow} currency={user.currency} simplify={true} size="h7" />
                <MetricCard t={t} title='capital_gains' value={forecastData?.capital_gains} currency={user.currency} simplify={true} size="h7" />
                <MetricCard t={t} title='profit' value={forecastData?.profit} currency={user.currency} simplify={true} size="h7" />
            </Box>
        </Box>
    );
});

export default ForecastPlan;

import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { formatAmount } from '../../utils'; // Assuming you have a utility function for formatting numbers

const Details = ({ asset, stats, details, location, currency, t }) => {
  // Destructure the asset object to extract only the fields you need
  const {
    type,
    category,
    exploitation,
    name,
    description,
    purchase_date,
    purchase_price,
    acquisition_cost,
    cashflow,
    debt_interest,
    gains
  } = asset;

  const excludedKeys = ['id', 'category'];
  const is_financial_product = type === 'FINANCIAL_PRODUCT'
  // Helper function to format the asset details
  const formatDetail = (label, value, currency = '') => {

    let displayValue = value;
    if (currency) {
      displayValue = formatAmount(displayValue, currency);
    }
    else if (label === 'surface')
      // Check if the label is 'surface' to add 'm²' suffix
      displayValue = `${value} m²`;

    return (
      <Typography variant="body2">
        <strong>{t(label)}:</strong> {displayValue}
      </Typography>
    );
  };

  return (
    <Box sx={{ m: 0, mb: 2, backgroundColor: 'rgba(0, 0, 0, 0.03)', p: 0.5 }} >
      <Card elevation={0} sx={{ p: 2, backgroundColor: 'transparent' }}>
        <CardContent>
          <Typography variant="h6" component="h6" sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
            border: '1px solid #e0e0e0',
            p: 1
          }}>
            {name} {/* Replace with your actual title key */}
          </Typography>

          <Typography color="text.secondary" gutterBottom>
            {description}
          </Typography>
          <Box sx={{ my: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {formatDetail('type', t(type))}
                {formatDetail('category', t(category))}
                {exploitation && formatDetail('exploitation', t(exploitation))}
                {formatDetail('ownership', (asset.ownership * 100).toFixed(0) + '%')}
                {formatDetail('purchase_date', purchase_date)}
              </Grid>
              <hr className="nav-divider" />
              <Grid item xs={12} sm={6}>
                {formatDetail('purchase_price', purchase_price, currency)}
                {formatDetail('acquisition_cost', acquisition_cost, currency)}

                {is_financial_product && formatDetail('capital_gains', gains, currency)}

              </Grid>
              <hr className="nav-divider" />

              {details && (
                <Grid item xs={12} sm={6}>
                  {Object.entries(details).map(([key, value]) => (
                    (value && !excludedKeys.includes(key) && !key.endsWith('id')) && (
                      <div key={key}>
                        {formatDetail(key, value)}
                      </div>
                    )
                  ))}
                </Grid>
              )}
              <hr className="nav-divider" />

              {location && (
                <Grid item xs={12} sm={6}>
                  {/* Render detailsWithoutIds properties */}
                  {Object.entries(location).map(([key, value]) => (
                    (value && !excludedKeys.includes(key) && !key.endsWith('id')) && (
                      <div key={key}>
                        {formatDetail(key, value)}
                      </div>
                    )
                  ))}
                </Grid>
              )}

              <hr className="nav-divider" />
              <Grid item xs={12} sm={6}>
                {formatDetail('cashflow', cashflow, currency)}
                {formatDetail('cumulative_roi', (stats.cumulative_roi * 100).toFixed(2) + '%')}
                {stats.occupancy_rate != 0 && formatDetail('occupancy', (stats.occupancy_rate*100).toFixed(2) + '%')}
              </Grid>


            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Details;

import React, { useEffect, useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Typography,
  Grid,
} from '@mui/material';
import { LanguageContext } from '../../context/LanguageContext';
import { useNotification } from '../../context/NotificationContext';
import { updateUser, configCurrencies, createUser, configCountries } from '../../api';
import { useTranslation } from 'react-i18next';
import enFlag from '../../resources/en_flag.svg';
import frFlag from '../../resources/fr_flag.svg';

const ProfileEdit = ({ user, saveUser, open, onClose, onSuccess = () => {}, readOnly = false }) => {
  const { t, i18n } = useTranslation();
  const [currencies, setCurrencies] = useState([]);
  const defaultCountry = 'Côte d\'Ivoire';
  const [countriesList, setCountries] = useState([defaultCountry]);
  const { language, changeLanguage } = useContext(LanguageContext);
  const { showNotification } = useNotification();
  const { control, handleSubmit, watch, setValue, formState: { errors, isValid } } = useForm({
    defaultValues: {
      ...user,
      language: i18n.language,
      currency: user.currency || "",
      role: user.role || "INDIVIDUAL",
      code: ""
    },
    mode: "onChange",
  });

  const selectedRole = watch("role");

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const res = await configCurrencies();
        setCurrencies(res.data);
      } catch (error) {
        console.error('Failed to fetch currencies', error);
      }
    };
    fetchCurrencies();
  }, [user.currency]);

  useEffect(() => {
    configCountries().then(response => {
      if (!response.data.includes(defaultCountry)) {
        response.data.unshift(defaultCountry);
      }
      setCountries(response.data);
    });
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      data['user_id'] = user.id;
      data['language'] = i18n.language;
      const response = readOnly ? await updateUser(data) : await createUser(data);
      saveUser(response.data);
      showNotification(t('profile_edit_successful'));
      onClose();
      onSuccess();
    } catch (error) {
      console.error('Failed to update user', error);
      const detail = error.response?.data?.detail;
      showNotification(t('profile_edit_failed') + detail, 'error');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ backgroundColor: '#f5f5f5' }}>
        {user.name ? t('edit_user') : t('add_info')}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={t('name')}
                    error={!!errors.name}
                    helperText={errors.name ? t('name_required') : ''}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="surname"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={t('surname')}
                    error={!!errors.surname}
                    helperText={errors.surname ? t('surname_required') : ''}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="country"
                control={control}
                rules={{ required: t('required') }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    options={countriesList}
                    getOptionLabel={(option) => option || ""}
                    value={field.value || ""}
                    onChange={(event, value) => field.onChange(value || "")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('country')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={t('phone')}
                    type="tel"
                    error={!!errors.phone}
                    helperText={errors.phone ? t('phone_required') : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                <InputLabel>{t('account_type')}</InputLabel>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label={t('account_type')} disabled={readOnly}>
                      <MenuItem value="INDIVIDUAL">{t('INDIVIDUAL')}</MenuItem>
                      <MenuItem value="ADMIN">{t('ADMIN')}</MenuItem>
                      <MenuItem value="PRO">{t('PRO')}</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            {(selectedRole === "PRO") && !readOnly && (
              <Grid item xs={12}>
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('company_code')}
                      error={!!errors.code}
                      helperText={errors.code ? t('code_required') : ''}
                      required
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>{t('currency')}</InputLabel>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label={t('currency')} disabled={readOnly}>
                      {currencies.map((curr) => (
                        <MenuItem key={curr} value={curr}>{curr}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="body2" color="textSecondary">{t('language')}</Typography>
              <Button variant="outlined" onClick={() => changeLanguage(language === 'en' ? 'fr' : 'en')} style={{ marginLeft: '10px' }}>
                <img className="language-flag" src={language === 'en' ? enFlag : frFlag} alt="Language Flag" />
              </Button>
            </Grid>
          </Grid>
          {!readOnly && (
            <>
              <Divider sx={{ my: 1 }} />
              <Typography variant='caption'>{t('phone_privacy')}</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t('cancel')}
          </Button>
          <Button color="primary" type="submit" disabled={!isValid}>
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProfileEdit;

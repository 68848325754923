import React, { useState, useContext, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {

  Box,
  Container,
  Fab,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import ConciergeForm from './ConciergeForm';
import ConciergeCard from './ConciergeCard';
import { useNotification } from '../../context/NotificationContext';
import ContractForm from '../contracts/ContractForm'; // Import ContractForm
import { createConcierge, getConcierges, createContract, updateConcierge, deleteConcierge, getConcierge, getAssets } from '../../api'; // Add getAssets

export default function Concierges() {
  const { user, refreshUser } = useContext(UserContext);
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [basicModal, setBasicModal] = useState(false);
  const [contractModal, setContractModal] = useState(false); // State to control contract modal
  const [selectedConcierge, setSelectedConcierge] = useState(null);
  const [concierges, setConcierges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState([]); // State to store assets
  const [preselectedConcierge, setPreselectedConcierge] = useState(null); // State for preselected concierge
  const queryClient = useQueryClient();
  const toggleShow = () => setBasicModal(!basicModal);
  const toggleContractShow = () => setContractModal(!contractModal); // Toggle contract modal

  const createContractMutation = useMutation(
    ({ asset_id, contract }) => createContract(asset_id, contract),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contracts');
        refreshUser();
      },
    }
  );

  const fetchConcierges = async () => {
    try {
      setIsLoading(true);
      if (user.role !== "PRO") {
        const response = await getConcierges();
        setConcierges(response.data);
      }
      else if (user.concierge_id) {
        const response = await getConcierge(user.concierge_id);
        setConcierges([response.data]);
      }
    } catch (error) {
      console.error('An error occurred while fetching concierges:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewContract = async (formData) => {
    try {
      setIsLoading(true);
      const { asset_id, concierge_id, ...rest } = formData;
      await createContractMutation.mutateAsync({ asset_id, contract: formData });
      showNotification(t('contract_created_successfully'), 'success');
      toggleShow();
    } catch (error) {
      console.error('An error occurred while creating the contract:', error);
      showNotification(t('contract_creation_failed') + ': ' + error.response?.data?.detail || error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssets = async () => {
    try {
      const response = await getAssets(); // Fetch assets to be used in ContractForm
      setAssets(response.data);
    } catch (error) {
      console.error('An error occurred while fetching assets:', error);
    }
  };

  useEffect(() => {
    fetchConcierges();
    fetchAssets(); // Fetch assets on component mount
  }, [user]);

  const handleNewConcierge = async (formData) => {
    try {
      setIsLoading(true);
      if (selectedConcierge) {
        await updateConcierge(selectedConcierge.id, formData);
        showNotification(t('concierge_updated_successfully'), 'success');
      } else {
        await createConcierge(formData);
        showNotification(t('concierge_created_successfully'), 'success');
      }
      fetchConcierges();
      refreshUser();
      toggleShow();
    } catch (error) {
      console.error('An error occurred while creating/updating the concierge:', error);
      showNotification(t('concierge_creation_failed') + ': ' + error.response?.data?.detail || error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditConcierge = (concierge) => {
    setSelectedConcierge(concierge);
    toggleShow();
  };

  const handleDeleteConcierge = async (conciergeId) => {
    try {
      setIsLoading(true);
      await deleteConcierge(conciergeId);
      showNotification(t('concierge_deleted_successfully'), 'success');
      fetchConcierges();
    } catch (error) {
      console.error('An error occurred while deleting the concierge:', error);
      showNotification(t('concierge_deletion_failed') + ': ' + error.response?.data?.detail || error.message, 'error');

    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestService = (concierge) => {
    setPreselectedConcierge(concierge); // Set the concierge to be preselected
    toggleContractShow();
  };

  return (
    <Container maxWidth="md">
      {(isLoading) ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="center" my={3}>
            <Typography variant="h4">{user.role !== "PRO" ? t('concierges') : t('info')}</Typography>
          </Box>
          {concierges.map((concierge) => (
              <ConciergeCard
              key = { concierge.id }
              concierge = { concierge }
              onRequest = { handleRequestService } // Pass handleRequestService function
              onEdit = { handleEditConcierge }
              onDelete = { handleDeleteConcierge }
              role = { user?.role}
            />
          ))}


          {user?.role === 'ADMIN' && (
            <Fab
              color="primary"
              aria-label={t('add_concierge')}
              onClick={() => {
                setSelectedConcierge(null);
                toggleShow();
              }}
              sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                width: 64,
                height: 64,
                fontSize: 32,
              }}
            >
              <AddIcon />
            </Fab>
          )}
          <Dialog open={basicModal} onClose={toggleShow} fullWidth maxWidth="sm">
            <DialogTitle sx={{ backgroundColor: '#f5f5f5' }}>
              {selectedConcierge ? t('edit_concierge') : t('create_concierge')}
            </DialogTitle>
            <DialogContent>
              <ConciergeForm
                user={user}
                apply={handleNewConcierge}
                toggleShow={toggleShow}
                concierge={selectedConcierge}
              />
            </DialogContent>
          </Dialog>

          {/* Contract Form Dialog */}
          <Dialog open={contractModal} onClose={toggleContractShow} fullWidth maxWidth="sm">
            <DialogTitle sx={{ backgroundColor: '#f5f5f5' }}>
              {t('contract_request')}
            </DialogTitle>
            <DialogContent>
              <ContractForm
                user={user}
                apply={handleNewContract}
                toggleShow={toggleContractShow}
                assets={assets}
                concierges={concierges}
                preselectedConcierge={preselectedConcierge} // Pass preselected concierge
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Container>
  );
}

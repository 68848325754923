import React, { createContext, useState, useEffect } from "react";
import i18n from "../i18n";
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("fr");

  const changeLanguage = (lng) => {
    setLanguage(lng);
    saveLang(lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    // Attempt to load the user data from local storage on initial render
    const storedLang = JSON.parse(localStorage.getItem('language'));
    if (storedLang) {
      setLanguage(storedLang);
      i18n.changeLanguage(storedLang);
    }
  }, []);

  const saveLang = (lng) => {
    localStorage.setItem('language', JSON.stringify(lng));
  };
  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// LoadingModal.js
import React from 'react';
import { Modal, CircularProgress, Box } from '@mui/material';

const LoadingModal = ({ open }) => (
  <Modal
    open={open}
    closeAfterTransition
    BackdropProps={{
      timeout: 500,
    }}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Box>
  </Modal>
);

export default LoadingModal;

import React, { useState, useRef } from 'react';
import TypeSelector from './steps/TypeSelector';
import DetailsImmo from './steps/DetailsImmo';
import DetailsAuto from './steps/DetailsAuto';
import Location from './steps/Location';
import { MobileStepper, Button, Box, Typography } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import { useNotification } from '../../context/NotificationContext';

const EstimationCreationWizard = ({ user, t, applyEstimation, onCancel }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [details, setDetails] = useState({});
  const [location, setLocation] = useState({});
  const theme = useTheme();
  const { showNotification } = useNotification();

  const steps = ['type', 'location', 'characteristics'];

  // Determine if the current step is the last step
  const isLastStep = steps ? activeStep === steps.length - 1 : false;

  const handleSubmit = (formData) => {
    console.log("handleSubmit ", formData);
    const currentStep = steps[activeStep];
    console.log("AssetCreationWizard - handleSubmit", formData);

    switch (currentStep) {
      case 'location':
        setLocation(currentData => ({ ...currentData, ...formData }));
        break;
      case 'characteristics':
      case 'type':
      case 'details':
        setDetails(currentData => ({ ...currentData, ...formData }));
        break;
      default:
        console.log("No matching case for the current step");
    }

    // Move to the next step
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };


  const stepRefs = useRef({});

  const handleNext = () => {
    const currentStepKey = steps && steps.length > activeStep ? steps[activeStep] : null;
    const currentStepRef = stepRefs.current[currentStepKey];

    if (currentStepRef && currentStepRef.submitForm) {
      currentStepRef.submitForm();
    } else {
      if (activeStep < steps.length - 1) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      onCancel();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const stepComponentMap = {
    // Define mapping for different steps to their respective components
    location: Location,
    characteristics: DetailsImmo, // or DetailsAuto, depending on your logic
    type: TypeSelector
  };
  const handleCharactUpdate = (newData) => {
    setDetails(currentData => ({ ...currentData, ...newData }));
  };


  const getStepContent = (stepIndex) => {
    const step = steps[stepIndex];
    if (step === 'type') {
      return <TypeSelector onSelect={typeSelected} />;
    }
    const stepProps = {
      onSelect: handleSubmit,
      onValidSubmit: handleSubmit,
      ref: el => stepRefs.current[step] = el,
      assetIn: details,
      user,
      t,
      onCharacUpdate: step === 'characteristics' ? handleCharactUpdate : undefined,
      memo: step === 'location' || step === 'characteristics' || step === 'details' ? details : undefined
    };



    const StepComponent = stepComponentMap[step];
    return StepComponent ? <StepComponent {...stepProps} /> : <div>Step not defined</div>;
  };

  const handleFinalSubmit = () => {
    // Proceed with the final submission
    details['user_id'] = user.id;
    details['category'] = details['type'];
    console.log("Final estimation submission data: ", details, location);
    // Check pays is cote d ivoire
    if (location.country === 'Côte d\'Ivoire') {
      applyEstimation(details, location);
    }
    else {
      showNotification(t('location_not_supported'));
    }
  };

  const typeSelected = (selectedType) => {
    setDetails(current => ({ ...current, type: selectedType }));
    setActiveStep(1); // Move to the next step after type selection
  };

  return (
    <Box sx={{ width: '100%', flexGrow: 1, mt: -1 }}>
     <Typography variant="h6" align="center" sx={{ my: 2 }}>
        {t(steps[activeStep])}
      </Typography>
      {getStepContent(activeStep)}

      {/* Mobile Stepper */}
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        sx={{
          mt: 1,
          backgroundColor: 'transparent', // Custom background color
          '.MuiMobileStepper-dot': {
            backgroundColor: theme.palette.grey[400], // Custom dot color
          },
          '.MuiMobileStepper-dotActive': {
            backgroundColor: theme.palette.primary.main, // Custom active dot color
          },
        }}
        nextButton={
          isLastStep ? (
            <Button variant="contained" color="primary" onClick={handleFinalSubmit}>{t('submit')}</Button>
          ) : (
            <Button size="small" onClick={handleNext} disabled={activeStep === 0}>
              {t('next')}
              <KeyboardArrowRight />
            </Button>
          )
        }
        backButton={
          <Button size="small" onClick={handleBack}>
            <KeyboardArrowLeft />
            {t('back')}
          </Button>
        }
      />
    </Box>
  );
};

export default EstimationCreationWizard;

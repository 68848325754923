import React, { useState, useContext } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  Box, Typography, IconButton, Container, CircularProgress,
  Fab, Modal
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getTransactionsPro, createTransactionPro, updateTransaction, deleteTransaction } from '../../api';
import { UserContext } from '../../context/UserContext';
import TransactionList from '../../utils/TransactionList'; // Adjust the path as needed
import FormTransaction from './FormTransaction'; // Assuming you have a form component for transaction
import { useTranslation } from 'react-i18next';

// TopBar component
const TopBar = ({ t }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
      <IconButton edge="start" color="inherit" onClick={() => navigate(-1)} aria-label="back">
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4" align="center">{t('transactions')}</Typography>
      <div style={{ width: 48 }} /> {/* To balance the space on the right */}
    </Box>
  );
};

// TransactionsPro component
function TransactionsPro() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editTransactionData, setEditTransactionData] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: transactions = [], isLoading: transactionsLoading, refetch: refetchTransactions } = useQuery(
    ['transactions', user.id],
    async () => {
      const response = await getTransactionsPro(user.concierge_id);
      return response.data;  // Directly return the data object from the response
    }
  );

  const createTransactionProMutation = useMutation(
    (newTransaction) => createTransactionPro(newTransaction),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('transactions');
        setCreateModal(false);
      }
    }
  );

  const updateTransactionMutation = useMutation(
    (updatedTransaction) => updateTransaction(editTransactionData.id, updatedTransaction),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('transactions');
        setEditModal(false);
      }
    }
  );

  const deleteTransactionMutation = useMutation(
    (transactionId) => deleteTransaction(transactionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('transactions');
      }
    }
  );

  const handleDelete = async (transactionId) => {
    if (window.confirm(t('confirm_delete_transaction'))) {
      deleteTransactionMutation.mutate(transactionId);
    }
  };

  const handleEdit = (transactionId) => {
    const transactionToEdit = transactions.find((tx) => tx.id === transactionId);
    setEditTransactionData(transactionToEdit);
    setEditModal(true);
  };

  const handleCreateSubmit = (formData) => {
    createTransactionProMutation.mutate(formData);
  };

  const handleEditSubmit = (formData) => {
    updateTransactionMutation.mutate(formData);
  };

  return (
    <Container maxWidth="lg" sx={{ mb: 4 }}>
      {transactionsLoading ? (
        // Loading UI
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TopBar t={t} />

          <TransactionList
            title={t('transactions')}
            transactions={transactions}
            currency={user.currency}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />

          <Fab color="primary" aria-label={t('add_transaction')} onClick={() => setCreateModal(true)}
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              width: 64,
              height: 64,
              fontSize: 32,
            }}
          >
            <AddIcon />
          </Fab>

          {/* Create Transaction Modal */}
          <Modal open={createModal} onClose={() => setCreateModal(false)}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <FormTransaction apply={handleCreateSubmit} toggleShow={() => setCreateModal(false)} />
            </Box>
          </Modal>

          {/* Edit Transaction Modal */}
          <Modal open={editModal} onClose={() => setEditModal(false)}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <FormTransaction apply={handleEditSubmit} toggleShow={() => setEditModal(false)} initialData={editTransactionData} />
            </Box>
          </Modal>
        </>
      )}
    </Container>
  );
}

export default TransactionsPro;

import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Container, Divider, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import './Topbar.css';

function Topbar({ toggleSidebar, isMobile, isLoginSignupPage }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="fixed" className="topbar" sx={{ backgroundColor: '#011826', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {isMobile && !isLoginSignupPage && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleSidebar}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon sx={{ fontSize: '2rem' }} />
                        </IconButton>
                    )}

                    <Typography
                        variant="h4"
                        component="div"
                        className="brand"
                        onClick={() => navigate('/dashboard')}
                        sx={{
                            flexGrow: 1,
                            textDecoration: 'none',
                            color: 'inherit',
                            fontSize: '1.8rem',
                            fontWeight: 'bold',
                            letterSpacing: '2px',
                            cursor: 'pointer',
                        }}
                    >
                        YEFINA
                    </Typography>

                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle sx={{ fontSize: '2rem' }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {user ? (
                                <>
                                    <MenuItem >
                                        <ListItemIcon>
                                            <AccountCircle fontSize="small" />
                                        </ListItemIcon>
                                        <div>
                                            <Typography variant="subtitle1">{`${user.name} ${user.surname}`}</Typography>
                                            <Typography variant="body2" color="textSecondary">{user.email}</Typography>
                                        </div>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={() => { handleClose(); navigate('/profile'); }}>
                                        <ListItemIcon>
                                            <SettingsIcon fontSize="small" />
                                        </ListItemIcon>
                                        {t('profile')}
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        onClick={() => { handleClose(); navigate('/logout'); }}
                                        sx={{ color: 'red' }} // Set Logout menu item color to red
                                    >
                                        <ListItemIcon>
                                            <LogoutIcon fontSize="small" sx={{ color: 'red' }} /> {/* Set icon color to red */}
                                        </ListItemIcon>
                                        {t('logout')}
                                    </MenuItem>
                                </>
                            ) : (
                                <MenuItem onClick={() => { handleClose(); navigate('/login'); }}>{t('login')}</MenuItem>
                            )}
                        </Menu>
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Topbar;

import React, { useContext, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Container,
  Fab,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import {
  getPendingTransactions,
  createPendingTransaction,
  deletePendingTransaction,
  rejectPendingTransaction,
  approvePendingTransaction,
  submitPendingTransaction,
  getManagedAssets,
  getOwners,
  getPartners,
  updatePendingTransaction,
} from '../../api';
import TransactionManager from './TransactionManager';
import TransactionList from './TransactionList';
import i18next from 'i18next';
import { useNotification } from '../../context/NotificationContext';

export default function Transactions() {
  const { user, refreshUser } = useContext(UserContext);
  const { t } = useTranslation();
  const [basicModal, setBasicModal] = useState(false);
  const [rejectModal, setRejectModal] = useState({ open: false, transactionId: null });
  const [rejectNote, setRejectNote] = useState('');
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [initialData, setInitialData] = useState(null);
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();

  const toggleShow = () => {
    setInitialData(null);
    setBasicModal(!basicModal);
  };

  const { data: transactions, isLoading: transactionsLoading } = useQuery(
    'pending_transactions',
    () => getPendingTransactions(user.id),
    {
      enabled: !!user?.id,
      select: (response) => response.data || [],
    }
  );

  const { data: assets, isLoading: assetsLoading } = useQuery(
    'assets',
    () => getManagedAssets(),
    {
      enabled: !!user?.id && user.role=='PRO',
      select: (response) => response.data || [],
    }
  );

  const { data: owners, isLoading: ownersLoading } = useQuery(
    'owners',
    () => getOwners(user.id),
    {
      enabled: !!user?.id && user.role=='PRO',
      select: (response) => response.data || [],
    }
  );

  const { data: partners, isLoading: partnersLoading } = useQuery(
    'partners',
    () => getPartners(user.id) && user.role=='PRO',
    {
      enabled: !!user?.id,
      select: (response) => response.data || [],
    }
  );

  const createTransactionMutation = useMutation(
    ({ asset_id, transaction }) => createPendingTransaction(asset_id, transaction),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pending_transactions');
        refreshUser();
      },
    }
  );

  const updateTransactionMutation = useMutation(
    ({ transaction_id, transaction }) => updatePendingTransaction(transaction_id, transaction),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pending_transactions');
        refreshUser();
      },
    }
  );

  const rejectTransactionMutation = useMutation(
    ({ transaction_id, note }) => rejectPendingTransaction(transaction_id, note),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pending_transactions');
        refreshUser();
      },
    }
  );

  const approveTransactionMutation = useMutation(
    ({ transaction_id }) => approvePendingTransaction(transaction_id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pending_transactions');
        refreshUser();
      },
    }
  );

  const deleteTransactionMutation = useMutation(
    ({ transaction_id }) => deletePendingTransaction(transaction_id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pending_transactions');
        refreshUser();
      },
    }
  );

  const submitTransactionMutation = useMutation(
    ({ transaction_id }) => submitPendingTransaction(transaction_id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pending_transactions');
        refreshUser();
      },
    }
  );

  const handleNewTransaction = async (formData) => {
    try {
      setIsLoading(true);
      const { asset_id, ...rest } = formData;
      await createTransactionMutation.mutateAsync({ asset_id, transaction: formData });
      showNotification(t('transaction_created_successfully'), 'success');
      toggleShow();
    } catch (error) {
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateTransaction = async (formData, status) => {
    try {
      setIsLoading(true);
      const { id, asset_id, ...rest } = formData;
      formData.status = status;
      await updateTransactionMutation.mutateAsync({ transaction_id: id, transaction: formData });
      showNotification(t('transaction_updated_successfully'), 'success');
      toggleShow();
    } catch (error) {
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleApproveTransaction = async (transactionId) => {
    try {
      setIsLoading(true);
      await approveTransactionMutation.mutateAsync({ transaction_id: transactionId });
      showNotification(t('transaction_approved_successfully'), 'success');
    } catch (error) {
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectTransaction = async () => {
    try {
      setIsLoading(true);
      await rejectTransactionMutation.mutateAsync({ transaction_id: rejectModal.transactionId, note: rejectNote });
      showNotification(t('transaction_rejected_successfully'), 'success');
      setRejectModal({ open: false, transactionId: null });
      setRejectNote('');
    } catch (error) {
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTransaction = async (transactionId) => {
    if (!window.confirm(t('confirm_delete_transaction'))) {
      return; // Exit the function if user cancels confirmation
    }

    try {
      setIsLoading(true);
      await deleteTransactionMutation.mutateAsync({ transaction_id: transactionId });;
      showNotification(t('transaction_deleted_successfully'), 'success');
    } catch (error) {
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitTransaction = async (transactionId) => {
    try {
      setIsLoading(true);
      await submitTransactionMutation.mutateAsync({ transaction_id: transactionId });
      showNotification(t('transaction_submitted_successfully'), 'success');
    } catch (error) {
      showNotification(t('transaction_submission_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenRejectModal = (transactionId) => {
    setRejectModal({ open: true, transactionId });
  };

  const handleCloseRejectModal = () => {
    setRejectModal({ open: false, transactionId: null });
    setRejectNote('');
  };

  const openUpdateTransaction = (transaction) => {
    setEditingTransaction(transaction);
    const asset = assets.find(a => a.id === transaction.asset_id);
    const owner = owners.find(c => c.id === asset?.user_id);
    setInitialData({
      id: transaction.id,
      owner_id: owner?.id || '',
      asset_id: transaction.asset_id,
      date: transaction.date,
      transactions: transaction.details,
      partner_id: transaction.partner_id,
    });
    setBasicModal(true);
  };

  const handleSubmitForm = (formData) => {
    if (editingTransaction) {
      handleUpdateTransaction(formData, editingTransaction.status);
    } else {
      handleNewTransaction(formData);
    }
  };

  if (transactionsLoading || (user.role !== 'INDIVIDUAL' && assetsLoading) || isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Define status colors and labels
  const statusColors = {
    DRAFT: 'info',
    SUBMITTED: 'primary',
    VALIDATED: 'success',
    REJECTED: 'error',
    REFUSED: 'warning',
    PENDING: 'default',
  };
  const statusLabels = {
    DRAFT: t('draft'),
    SUBMITTED: t('submitted'),
    VALIDATED: t('validated'),
    REJECTED: t('rejected'),
    REFUSED: t('refused'),
    PENDING: t('pending'),
  };

  const formatDate = (dateString) => new Date(dateString).toLocaleDateString(i18next.language);
  const pendingTransactions = (transactions || []);

  // Group transactions by owner if the user is a PRO
  const groupedTransactions = user.role === 'PRO'
    ? pendingTransactions.reduce((acc, transaction) => {
      const asset = assets?.find(a => a.id === transaction?.asset_id);
      const owner = asset?.user_id && owners?.find(c => c.id === asset.user_id);
      const ownerName = owner ? `${owner.name} ${owner.surname}` : t('unknown');

      if (!acc[ownerName]) {
        acc[ownerName] = [];
      }
      acc[ownerName].push(transaction);
      return acc;
    }, {})
    : { '': pendingTransactions }; // No grouping for non-PRO users

  const handleExpandClick = (ownerName) => {
    setExpanded(prevExpanded => ({
      ...prevExpanded,
      [ownerName]: !prevExpanded[ownerName],
    }));
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" my={3}>
        <Typography variant="h4">{t('management')}</Typography>
      </Box>

      {!transactions || transactions.length === 0 ? (
        <Box textAlign="center" m={3}>
          <Typography variant="subtitle1" color="text.secondary">
            {user.role === "PRO" && t('no_pending_transaction_prompt')}
            {user.role !== "PRO" && t('no_pending_transaction_prompt_no_pro')}
          </Typography>
          {user.role === "PRO" && <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={toggleShow}
            sx={{ mt: 3 }}
            disabled={!(assets && assets.length > 0)}
          >
            {t('add_transaction')}
          </Button>}
        </Box>
      ) : (
        <>
        <TransactionList
          user={user}
          groupedTransactions={groupedTransactions}
          expanded={expanded}
          handleExpandClick={handleExpandClick}
          formatDate={formatDate}
          statusLabels={statusLabels}
          statusColors={statusColors}
          assets={assets}
          partners={partners}
          handleSubmitTransaction={handleSubmitTransaction}
          openUpdateTransaction={openUpdateTransaction}
          handleDeleteTransaction={handleDeleteTransaction}
          handleApproveTransaction={handleApproveTransaction}
          handleOpenRejectModal={handleOpenRejectModal}
        />

        <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={toggleShow}
        disabled={!(assets && assets.length > 0) || user.role === 'INDIVIDUAL'}
      >
        <AddIcon />
      </Fab>
      </>
      )}

      <Dialog open={basicModal} onClose={toggleShow} fullWidth maxWidth="md">
        <DialogTitle sx={{ backgroundColor: '#f5f5f5' }}>
          {t('transaction_request')}
        </DialogTitle>
        <DialogContent>
          <TransactionManager
            user={user}
            apply={handleSubmitForm}
            toggleShow={toggleShow}
            assets={assets}
            owners={owners}
            initialData={initialData}
            partners={partners}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={rejectModal.open} onClose={handleCloseRejectModal} fullWidth maxWidth="sm">
        <DialogTitle>{t('reject_transaction')}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t('reject_note')}
            multiline
            rows={4}
            value={rejectNote}
            onChange={(e) => setRejectNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRejectModal}>{t('cancel')}</Button>
          <Button onClick={handleRejectTransaction} color="secondary">{t('reject')}</Button>
        </DialogActions>
      </Dialog>



    </Container>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Container,
  Fab,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Grid,
  Collapse,
  List,
  ListItem,
  ListItemText,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import { useNotification } from '../../context/NotificationContext';
import { getPartners, createPartner, updatePartner, deletePartner, getConcierges } from '../../api';

const Partners = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentPartner, setCurrentPartner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [concierges, setConcierges] = useState([]);
  const [expanded, setExpanded] = useState({});


  useEffect(() => {
    if (user.role === 'ADMIN') {
      getConcierges().then(response => setConcierges(response.data));
    }
  }, [user.role]);

  const { data: partners, isLoading: partnersLoading } = useQuery(
    'partners',
    () => getPartners(user.id),
    {
      enabled: !!user?.id,
      select: (response) => {
        const partners = response.data || [];
        if (user.role === 'PRO') {
          return partners.filter(partner => !partner.is_concierge);
        }
        return partners;
      },
    }
  );

  const createPartnerMutation = useMutation(
    (partnerData) => createPartner(partnerData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('partners');
      },
    }
  );

  const updatePartnerMutation = useMutation(
    ({ partnerId, partnerData }) => updatePartner(partnerId, partnerData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('partners');
      },
    }
  );

  const deletePartnerMutation = useMutation(
    (partnerId) => deletePartner(partnerId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('partners');
      },
    }
  );

  const handleOpenDialog = (partner = null) => {
    setCurrentPartner(partner);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setCurrentPartner(null);
    setIsDialogOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData(event.target);
    const partnerData = {
      name: formData.get('name'),
      concierge_id: user.concierge_id,
      category: formData.get('category'),
      contact: {
        name: formData.get('contact_name'),
        phone: formData.get('contact_phone'),
        email: formData.get('contact_email'),
      },
    };

    try {
      if (currentPartner) {
        await updatePartnerMutation.mutateAsync({ partnerId: currentPartner.id, partnerData });
      } else {
        await createPartnerMutation.mutateAsync(partnerData);
      }
      handleCloseDialog();
    } catch (error) {
      console.error('An error occurred:', error);
      showNotification('Failed to save partner');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (partnerId) => {
    if (window.confirm('Are you sure you want to delete this partner?')) {
      setIsLoading(true);
      try {
        await deletePartnerMutation.mutateAsync(partnerId);
      } catch (error) {
        console.error('An error occurred:', error);
        showNotification('Failed to delete partner');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleExpandClick = (conciergeName) => {
    setExpanded(prevExpanded => ({
      ...prevExpanded,
      [conciergeName]: !prevExpanded[conciergeName],
    }));
  };

  if (partnersLoading || isLoading || isCreating) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const groupedPartners = user.role === 'ADMIN'
    ? partners.reduce((acc, partner) => {
        const concierge = concierges.find(m => m.id === partner.concierge_id);
        const conciergeName = concierge ? concierge.name : t('unknown');
        if (!acc[conciergeName]) {
          acc[conciergeName] = [];
        }
        acc[conciergeName].push(partner);
        return acc;
      }, {})
    : { '': partners };

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" my={3}>
        <Typography variant="h4">{t('partners')}</Typography>
      </Box>
      {partners && partners.length === 0 ? (
        <Box textAlign="center" m={3}>
          <Typography variant="subtitle1" color="text.secondary">
            {t('no_partners_prompt')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
            sx={{ mt: 3 }}
          >
            {t('add_partner')}
          </Button>
        </Box>
      ) : (
        <>

          <Box display="flex" justifyContent="space-between" my={3}>

            <Fab
              color="primary"
              aria-label={t('add_partner')}
              onClick={() => handleOpenDialog()}
              sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                width: 64,
                height: 64,
                fontSize: 32,
              }}
            >
              <AddIcon />
            </Fab>
          </Box>
          {Object.entries(groupedPartners).map(([conciergeName, conciergePartners]) => (
            <Box key={conciergeName} sx={{ mb: 2 }}>
              {user.role === 'ADMIN' && (
                <List>
                  <ListItem button onClick={() => handleExpandClick(conciergeName)}>
                    <ListItemText primary={`${conciergeName} (${conciergePartners.length})`} />
                    {expanded[conciergeName] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={expanded[conciergeName]} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                      {conciergePartners.map((partner) => (
                        <Grid item xs={12} key={partner.id}>
                          <Card>
                            <CardContent>
                              <Typography variant="h6">{partner.name}</Typography>
                              <Typography variant="subtitle1">{t('Category')}: {partner.category}</Typography>
                              {partner.contact?.name && <Typography variant="body1">{t('contact_name')}: {partner.contact?.name}</Typography>}
                              {partner.contact?.phone && <Typography variant="body1">{t('contact_phone')}: {partner.contact?.phone}</Typography>}
                              {partner.contact?.email && <Typography variant="body1">{t('contact_email')}: {partner.contact?.email}</Typography>}
                            </CardContent>
                            <CardActions>
                              <Button size="small" color="primary" onClick={() => handleOpenDialog(partner)}>
                                {t('Edit')}
                              </Button>
                              <Button size="small" color="secondary" onClick={() => handleDelete(partner.id)}>
                                {t('Delete')}
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Collapse>
                </List>
              )}
              {user.role !== 'ADMIN' && conciergePartners.map((partner) => (
                <Grid item xs={12} key={partner.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{partner.name}</Typography>
                      <Typography variant="subtitle1">{t('Category')}: {partner.category}</Typography>
                      {partner.contact?.name && <Typography variant="body1">{t('contact_name')}: {partner.contact?.name}</Typography>}
                      {partner.contact?.phone && <Typography variant="body1">{t('contact_phone')}: {partner.contact?.phone}</Typography>}
                      {partner.contact?.email && <Typography variant="body1">{t('contact_email')}: {partner.contact?.email}</Typography>}
                    </CardContent>
                    <CardActions>
                      <Button size="small" color="primary" onClick={() => handleOpenDialog(partner)}>
                        {t('Edit')}
                      </Button>
                      <Button size="small" color="secondary" onClick={() => handleDelete(partner.id)}>
                        {t('Delete')}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Box>
          ))}
        </>
      )}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>{currentPartner ? t('Edit Partner') : t('Add Partner')}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Name')}
                  name="name"
                  defaultValue={currentPartner?.name || ''}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Category')}
                  name="category"
                  defaultValue={currentPartner?.category || ''}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('contact_name')}
                  name="contact_name"
                  defaultValue={currentPartner?.contact?.name || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('contact_phone')}
                  name="contact_phone"
                  defaultValue={currentPartner?.contact?.phone || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('contact_email')}
                  name="contact_email"
                  defaultValue={currentPartner?.contact?.email || ''}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="secondary">
                {t('Cancel')}
              </Button>
              <Button color="primary" type="submit" disabled={isLoading}>
                {t('Save')}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Partners;

import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const ClickableTooltip = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  // We will use a matchMedia query to check if the user is on a touchscreen device.
  const isTouchDevice = window.matchMedia("(hover: none)").matches;

  const handleTooltipClose = () => {
    if (isTouchDevice) setOpen(false);
  };

  const handleTooltipOpen = () => {
    if (isTouchDevice) setOpen(true);
  };

  // For non-touch devices, we'll allow the default hover behavior.
  const handleMouseEnter = () => {
    if (!isTouchDevice) setOpen(true);
  };

  const handleMouseLeave = () => {
    if (!isTouchDevice) setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener={isTouchDevice} // Only disable hover for touch devices
          disableTouchListener
          title={title}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div onClick={handleTooltipOpen}>
            {children}
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default ClickableTooltip;
